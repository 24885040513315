
import React from "react"
import Header from "../../Components/Header/Header";
import Blog from "../Home/Blog";
import Footer from "../../Components/Footer/Footer";
import Intro from "./Intro";
import Camps from "./Camps";
import Packages from "./Packages";
import Lessons from "./Lessons";



const index=({handleCart, cart})=>{
    return (
        <>
        <Header handleCart={handleCart} cart={cart}/>
        <React.Fragment>
            <Intro/>
            <Camps/>
            <Packages/>
            <Lessons/>
            {/* <Rentals/>
            <Transfers/> */}
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index;