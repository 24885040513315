import React, { Component } from 'react';
import "../Home/Home.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import camel from "../../assets/camel2.webp"
import tree from "../../assets/three-trees.webp"
import iconcustom2 from "../../assets/icon-custom2.webp"

import icon from "../../assets/restaurant.webp"
import icon2 from "../../assets/package-icon.webp"
import icon3 from "../../assets/customize-icon.webp"
import viewmore from "../../assets/view-more.webp"


const Restaurant = () => {
    const imgStyle = {
        height: '85px',

    }
    return (
        <>
            <section className='home-sec-5 py-5'>
                <div class="container">
                  
                <div className='row'>
                        <div className='title py-4'>
                            <img src={icon} /><h1>Restaurant</h1>
                        </div>
                        <p className='sec-3-desc py-4'>Kick back and relax in our beach club and restaurant designed for surfers, by surfers. Complete with breathtaking views and a stunning rooftop terrace, the Surf Station offers an oasis of calm after an exhilarating day in the waves.</p>
                    </div>
                    
                    <div className='row'>
                        <div className='col-lg-4 mt-2'>
                                <a href='/restaurant' className='see-direction'>LEARN MORE</a>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    );
}

export default Restaurant;
