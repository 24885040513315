import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'

import Feed from './Feed'

//import './instaFeeds.css'

const InstaFeeds = ({token, ...props}) => {
    const [feeds, setFeedsData] = useState([])
    //use useRef to store the latest value of the prop without firing the effect
    const tokenProp = useRef(token);
    tokenProp.current = token;

    useEffect(() => {
        // this is to avoid memory leaks
        const abortController = new AbortController();

        async function fetchInstagramPost () {
          try{
            axios
                //.get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=${props.limit}&access_token=${props.token}`)
                .get('https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=12&access_token=IGQVJXZAjdTckRqY0RyRTdUeEZAXOE12czN6VzhJdzdGZA0hNUG90SjVrUXBoTnprUFF1NGRPSVRxMGdaTkQzT1UySTVmaG5zQWhYaDR6UGczYlFmZAU9wR2FqTmxmaVNVUElJZAFZARcWhrWWlHMS1OcWk2SAZDZD')
                .then((resp) => {
                    setFeedsData(resp.data)
                })
          } catch (err) {
              console.log('error', err)
          }
        }
        
        // manually call the fecth function 
        fetchInstagramPost();
  
        return () => {
            // cancel pending fetch request on component unmount
            abortController.abort(); 
        };
    }, [props.limit])
    
    if(!feeds.data)
        return <></>
    return (
        <div className="container">
            <div className='row'>
                {feeds.data.map((feed) => (
                    <Feed key={feed.id} feed={feed} />
                ))}
            </div>
        </div>
    );
}

export default InstaFeeds;