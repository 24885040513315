import React, { Component, useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import "../Home/Home.css"
import "./index.css"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import sunset from '../../assets/sunset.jpg'
import ReactHtmlParser from 'react-html-parser';

const Desc = ({handleCart, cart}) => {
    const caroselStyle = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      },
    };

    const imgStyle = {
        height: '85px',
    }
    const cStyle = {
        height: '250px',
    }

    const [title, setName] = useState("");
    const [desc, setdesc] = useState('');
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const params = useParams();


    const getTeamDetails = async () => {
        console.warn(params);
        let result= await fetch(`${process.env.REACT_APP_BACKEND}/api/get-blog/${params.id}`);
        result = await result.json();
        setName(result.title);
        setdesc(result.desc);        
        setFileName(result.document)
        
      };
    
      useEffect(() => {
        getTeamDetails();
      }, []);

    return (
        <>
            <section className=' py-5'>
                <div class="container">
                    <div className='row'>
                    <img src={`${process.env.REACT_APP_BACKEND}/uploads/blogs/${fileName}`}/>

                    </div>

                    <div className='row '>
                        <div className='col-lg'>
                        <div className='title py-4'>
                            <h1>DESCRIPTION</h1>
                        </div>
                        <p className='desc-subhead'>
                        { ReactHtmlParser(desc) }
                        </p>
                        
                        </div>
                       
                    </div>



                </div>
            </section>
        </>
    );
}

export default Desc;
