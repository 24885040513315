import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Container, Row, Col } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import Moment from 'moment';
import '../index.css'
import { camps, packages } from '../../utils/config';
import CalendarModel from '../../Components/Model/CalenderModel';

const Intro = () => {
   const [date, setDate] = useState(new Date());
   const [bookings, setBookings] = useState([]);
   const [result, setResult] = useState();
   const [events,setEvents] = useState([])
   const [modalShow, setModalShow] = useState(false);
   const [bookingTitle, setBookingTitle] = useState(false);
   const [bookingId, setBookingId] = useState();
    const base_url = process.env.REACT_APP_BACKEND
   useEffect(() => {
        axios.get(`${base_url}/api/getBookings`).then(resp => {
            setBookings(resp.data.slice());
        });
    },[])

    useEffect(() => {
        if(bookings.length > 0){
            const data = []
            bookings.map(x => 
                x.detail.map(a => {
                    //console.log(x.detail);
                    let d = undefined;
                    if(a.campStartDate){
                        d = a.campStartDate;
                    }else if(a.reservationDate){
                        d = a.reservationDate;
                    }else if(a.dateOfArrival){
                        d = a.dateOfArrival;
                    }
                    
                    try{
                        let title = a.title
                        if(x.confirm){
                            if(x.confirm == 'yes')
                                title += "(Confirmed)"
                        }
                        d = Moment(d).format('YYYY-MM-DD')
                        const obj = 
                            { 
                                title: title,
                                date: d,
                                _id: x._id,
                                backgroundColor:"#" + Math.floor(Math.random() * 16777215).toString(16) 
                            }
                        if(a.duration || a.dateOfArrivalAndDeparture || a.bookingDate){
                            delete obj['date']
                            let startDate = d;
                            let endDate;
                            
                            if(a.dateOfArrivalAndDeparture || a.bookingDate)
                            {
                                const date = (a.dateOfArrivalAndDeparture || a.bookingDate).split(' to ');
                                startDate = Moment(date[0]).format('YYYY-MM-DD');
                                endDate = Moment(date[1]).format('YYYY-MM-DD');
                            }else{
                                endDate = Moment(d).add(a.duration.replace('d',''), 'days').format('YYYY-MM-DD');
                            }
                            if(startDate === endDate){
                                obj['date'] = startDate
                            }else{
                                obj['start'] = startDate
                                obj['end'] = endDate
                            }
                        }
                        data.push(obj)
                    }catch(ex){
                        console.log(ex)
                    }
                    
                }
                )
            )
            setEvents(data);
        }
    },[bookings])

    const handleEventClick = (clickInfo) => {
        const _id = clickInfo.event.extendedProps._id;
        if(_id){
            axios.get(`${base_url}/api/getBooking/${_id}`).then(resp => {
                setResult(resp.data);
                setBookingTitle(clickInfo.event.title)
                setBookingId(_id)
                setModalShow(true)
            });
        }
    }
    function renderEventContent(eventInfo) {
        //console.log(eventInfo);
        return (
          <>
            <b>{eventInfo.timeText}</b>
            <i>{eventInfo.event.title}</i>
          </>
        )
    }


    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>All Bookings</h1>
                            </div>
                            <div className="col-sm-6">

                            </div>
                        </div>
                    </div>
                </section>
                
                <section className='booking-calendar'>
                {/* <Calendar value={date} onChange={handleDateChange} /> */}
                <FullCalendar
                    plugins={[ dayGridPlugin ]}
                    initialView="dayGridMonth"
                    themeSystem="Simplex"
                    //locale="es"
                    header={{
                        left: "prev,next",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay"
                    }}
                    //weekends={false}
                    events={events}
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    eventClick={handleEventClick}
                    eventContent={renderEventContent} // custom render function
                    displayEventEnd="true"
                    eventColor={"#" + Math.floor(Math.random() * 16777215).toString(16)}
                    // {[
                    //   { title: 'event 1', date: '2023-02-19' },
                    //   { title: 'event 2', date: '2023-02-20' }
                    // ]}
                />
                </section>
              

            </div>
            <CalendarModel
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={result}
                bookingtitle={bookingTitle}
                bookingid={bookingId}
                events={events}
                setEvents={setEvents}
            />
        </div>
    )
}

export default Intro;
