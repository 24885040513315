import React, { Component } from 'react';
import { Carousel } from 'antd';
import "../../Home/Home.css"
import "./index.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';

import t1 from "../../../assets/res-1.jpg"
import t2 from "../../../assets/res-2.jpg"
import t3 from "../../../assets/res-3.jpg"
import t4 from "../../../assets/res-4.jpg"



const Gallery = ({gallery}) => {
    const imgStyle = {
        height: '285px',
    }
    const rowStyle = {
        margin: '0 auto',
    }
  
    return (
        <>
        <section className='package-inner-sec py-5'>
            <div className="container">
                <div className='row'>
                    <div className='py-5'>
                        {/* <img src={icon} style={imgStyle} alt="Team icon" /> */}
                    </div>
                </div>
                
                
                
            </div>
            <div className='row text-center' style={rowStyle}>
                        {
                            gallery.map(c => <div className='col-lg-3'><img className='py-3' src={`${process.env.REACT_APP_BACKEND}/uploads/camps/${c}`} style={imgStyle} alt="" srcset="" /></div>)
                        }
                 
                </div>

                
        </section>
        </>
    );
}

export default Gallery;
