
import React from "react"
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Intro from "./Intro";


const index=({handleCart, cart})=>{
    return (
        <>
        <Header handleCart={handleCart} cart={cart}/>
        <React.Fragment>
            <Intro itemInCart={cart}/>
            
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index;