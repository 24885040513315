import React, { Component } from 'react';
import "../Contact/Contact.css"
import "../Home/Home.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import slide1 from "../../assets/slide-1.png"
import wave from "../../assets/wave.png"

const Intro = () => {
    const imgStyle = {
        width: '500px',

    }
    return (
        <>

            <div class="jumbotron jumbotron-fluid sidi-hero hero-sec-one contact-hero">
                <div class="container">
                    <div className='sidi-hero-slider'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <h1 class="text-black">CONTACT</h1>
                                <img src={wave} style={imgStyle} />
                                <div class="row">
                                    <div class="col-lg-3">
                                        <h3>ADDRESS</h3>
                                        <div class="dot-inner"></div>
                                    </div>
                                    <div class="col-lg-3">
                                    <h3>GETTING THERE</h3>
                                    </div>                     
                                </div>
                            </div>
                            <div className='col-lg-6 '>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Intro;
