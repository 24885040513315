import React, { Component, useState, useEffect } from 'react';
import "../Home/Home.css"
import "../Prices/Prices.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import campf from "../../assets/camp-featured.webp"
import tree from "../../assets/tree.png"
import icon from "../../assets/icon-custom3.webp"
import learn from "../../assets/learn_more.webp"
import { Link } from 'react-router-dom';
import Lesson from '../Prices/Lessons';
import Rentals from '../Prices/Rentals';
import Accomodation from '../Prices/Accomodation';
import Transfers from '../Prices/Transfers';


const Lessons = () => {
    const imgStyle = {
        height: '85px',

    }
    const campfeatured = {
        width: '100%',
    }
    const [name, SetName] = useState([]);

    useEffect(() => {
      getAccomodations();
    }, [])
  
    const getAccomodations = async () => {
      let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/camp/getAll`);
      result = await result.json();
      SetName(result);
    }
    const [showSection, setShowSection] = useState(false);
    const toggleSection = () => {
        setShowSection(!showSection);
      };
    return (
        <>
                    <section className='yellow-bg-stripss'></section>

            <section className='offers-sec-3 py-5' id='customized'>
                <div class="container">

                    <div className='row'>
                        <div className='title'>
                            <h1>CUSTOMIZE</h1>
                        </div>
                        {/* <p className='sec-3-subhead'>EXPERIENCE A SPECIAL TIME TOGETHER IN THE WAVES</p> */}
                        <p className='sec-3-desc'>You know what you want! Create and tailor your very own surf holiday by booking your transfer,
                            hotel and equipment hire all together on our website. Book directly online and have your stress-free
                            holiday booked and confirmed within minutes!
                        </p>
                    </div>

                    {/* <div className='row'>
                        <div className='col-4'>
                    <img onClick={toggleSection} src={learn}/>
                    </div>
                       
                    </div> */}
                </div>
            </section>
            <Rentals/>
            <Lesson/>
            <Accomodation/>
            <Transfers/>

            {/* <div
            style={{
                display: showSection ? 'block' : 'none',
                transition: 'height 0.5s ease-out',
                overflow: 'hidden',
                }}
            >
           

            </div> */}

        </>
    );
}

export default Lessons;
