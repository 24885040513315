import React, { Component,useState, useEffect, useRef } from 'react';
import "../../Home/Home.css"
import "./index.css"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import sunset from '../../../assets/sunset.jpg'
import axios from 'axios';
import { addInCart, triggerNativeEventFor } from '../../../utils/helper';
import { DatePicker } from 'antd';
import { toast } from 'react-hot-toast';


const Desc = ({handleCart, cart}) => {

    const caroselStyle = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      },
    };

    const imgStyle = {
        height: '85px',
    }
    const cStyle = {
        height: '250px',
    }

    const [result,setResult] = useState([])
    const [peoples,setPeoples] = useState([]);
    const [accoumodationType,setAccomodationType] = useState([]);
    const [accomodation,setAccomodation] = useState("")
    const [isAccInc,setIsAccInc] = useState(false)
    const [amount,setAmount] = useState(0)
    const [people,setPeople] = useState(0)
    const peopleRef = useRef()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/api/getPackages/couple`).then(resp => {
            setResult(resp.data.slice());
        });
    },[])

    useEffect(() => {
        const getAccomodations = async () => {
            let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/hotel/getAll`);
            result = await result.json();
            setAccomodationType(result);
          }
        getAccomodations();
    }, [])
    
    useEffect(() => {
        if(result.length>0){
            const list = result.map(x => x.people)
            setPeoples([...new Set(list)])
            triggerNativeEventFor(peopleRef.current,{ event: 'change', value: 2})
        }
    },[result])

    const handlePeople = (e) => {
        setPeople(e.target.value)
    }

    const handleAccomodotion = (e) => {
        setAccomodation(e.target.value)
    }

    useEffect(() => {
        if(people > 0){
            let data;
            if(accomodation && people) 
                data = result.find(x => x.people == people && accomodation == x.accomodationType);
            else
                data = result.find(x => x.people == people);

            if(data){
                setIsAccInc(data.accomodation == 'yes' ? true : false);
                setAmount(data.price);
            }
            triggerNativeEventFor(peopleRef.current,{ event: 'change', value: people})
        }
    },[people,peoples,accomodation])

    // if(lessonCategory.length === 0)
    //     return <></>
    const handleSubmit = (e) => {
        e.preventDefault();
        const form = document.forms.coupleForm
        if(amount <= 0){
            toast.error('please select camp')
            return;
        }else if(form.packageStartDate.value === undefined || form.packageStartDate.value === ""){
            toast.error('please select camp date!.')
            return;
        }
        const details = {
                category:'couple',
                people:form.people.value,
                accomodationType:form.accomodationType.value,
                campStartDate:form.packageStartDate.value,
                amount:amount
            }
        
        const itemInCart = addInCart(details,'couple');
        if(itemInCart.status){
            handleCart([...cart,details])
            toast.success('Item successfully add in cart.', {
                icon: '👏',
              })
        }else{
            toast.error(itemInCart.message)
        }
    }
    
    return (
        <>
            <section className='Desc-sec py-5 '>
                <div class="container">
                    <div className='row'>
                    <Carousel responsive={caroselStyle}>
                        <div><img src={sunset} style={cStyle} alt="" srcset="" /></div>
                        <div><img src={sunset}  style={cStyle} alt="" srcset="" /></div>
                        <div><img src={sunset}  style={cStyle} alt="" srcset="" /></div>
                        <div><img src={sunset}  style={cStyle} alt="" srcset="" /></div>
                    </Carousel>;

                    </div>

                    <div className='row white-frame'>
                        <div className='col-lg-6'>
                        <div className='title py-4'>
                            <h1>DESCRIPTION</h1>
                        </div>
                        <p className='desc-subhead'>
                        Looking for a surf holiday that doesn’t break the bank? Here at Sidi Kaouki Surf Station we tailor make surf packages to suit all budgets! Our budget package offers you an all-inclusive stay complete with breakfast, lunch and dinner everyday, daily surf tuition from our expert teachers and board and wetsuit hire for the week, proving that the perfect surf vacation needn’t be expensive.
                            <br/>
                            <br/>

                            You will stay in a centrally located hostel in dorm-style rooms that sleep  up to a maximum of 5 people and include a shared bathroom. The hostel is simple but has everything you could need for your stay including a shared lounge and rooftop where you can meet and hang out with other surfers!
                            <br/>
                            <br/>

                            You will also be spending lots of time at the Surf Station which boasts a restaurant and terrace overlooking the ocean where all meals will be served. Vegetarian and vegan options are available. On the ground floor is the surf shop that houses all surfing equipment and gear and includes a changing area and lockers where you can secure your belongings when you’re out on the waves.  Come join us for the perfect surf getaway on Morocco’s beautiful Atlantic coast!
                        </p>
                        
                        </div>
                        <div className='col-lg-6'>
                        <div className='title py-4'>
                            <h1>What’s included?</h1>            
                        </div>
                        <p className='desc-subhead'>
                                ► 7 nights hostel accommodation <br/>
                                ► Breakfast, lunch, and dinner at the Surf Station restaurant (7 days)<br/>
                                ► Daily 2-hour surf lessons (6 days)<br/>
                                ► Surfboard and wetsuit hire (6 days)<br/>
                            </p>
                            <form name='coupleForm' onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>No of people</label>
                                <select className="form-control" ref={peopleRef} name="people" onChange={handlePeople}>
                                    <option value={''}>Please Select Person</option> 
                                {peoples.length > 0 &&
                                    peoples.map(n => <option key={n} value={n}>{n} Person</option>)
                                }
                                </select>
                            </div>
                            {isAccInc &&
                                <div className="form-group">
                                    <label>ACCOMODATION</label>
                                    <select className="form-control" onChange={handleAccomodotion} name='accomodationType'>
                                        {accoumodationType.map((accomodation) => (
                                        <option key={accomodation._id} value={accomodation._id}>
                                            {accomodation.name}
                                        </option>
                                        ))}
                                    </select>
                                </div>
                            }
                             <div class="form-group">
                                    <label>Date:</label>
                                    <DatePicker
                                        name="packageStartDate"
                                        dateFormat="MM/dd/yyyy"
                                    />
                                    {/* <div class="input-group date" id="datebpkg" data-target-input="nearest">
                                        <input type="text" name="packageStartDate"  class="form-control datetimepicker-input" data-target="#datebpkg" />
                                        <div class="input-group-append" data-target="#datebpkg" data-toggle="datetimepicker">
                                            <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                                        </div>
                                    </div> */}
                              </div>
                              <div class="form-group">
                                <label>Total Amount: {amount}</label>
                              </div>
                              <button type="submit" className="btn book-now-btn"> <i className='fas fa-suitcase px-2'></i>ADD TO YOUR TRAVEL</button>

                            </form>

                        </div>
                    </div>



                </div>
            </section>
        </>
    );
}

export default Desc;
