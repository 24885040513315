import React, { Component } from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import New from './New'

export default class index extends Component {
  render() {
    return (
      <div className='wrapper'>
        <Header/>
        <Sidebar/>
        <New/>
        <Footer/>
      </div>
    )
  }
}
