
import React, { useState } from "react"
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import CheckoutForm from "./CheckoutFrom";
// Replace 'pk_test_...' with your own Stripe publishable key
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { getCartAmount } from "../../utils/helper";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const promise = loadStripe('pk_live_qY3OVz0Fgo95r5UL6COIkP4Y');

const index=({handleCart, cart})=>{
    const options = {
        // passing the client secret obtained from the server
        clientSecret: '{{CLIENT_SECRET}}',
      };
    const amount = getCartAmount();
    return (
        <>
        <Header handleCart={handleCart} cart={cart}/>
        <React.Fragment>
        <Elements stripe={promise}>
            <CheckoutForm amount={amount}/>
                {/* <Intro/> */}
                </Elements>
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index;