import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate , useParams } from "react-router-dom";


import '../../index.css'
import { peoples, roomTypes } from '../../../utils/config';
import { resetStorage } from '../../../utils/helper';

const UpdateAccomodations = () => {
    const [result, setResult] = useState("");
    const navigate = useNavigate();
    const params = useParams();
    const [hotels,setHotels] = useState([])
    const [isloaded, setLoaded] = useState(false)
    useEffect(()=>{
        if (!isloaded) {
            const getHotels = async () => {
                let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/hotel/getAll`);
                result = await result.json();
                setHotels(result);
            }
            getHotels();
            getAccomodationDetails();
            setLoaded(true)
          }
        return () => {}
        
    },[])
    
    const getAccomodationDetails = async()=>{
        let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/accomodation/get/${params.id}`);
        result = await result.json();
        setResult(result)
    }
    
    const submitAccomodations = async (e) =>{
        e.preventDefault();
        const userID = JSON.parse(localStorage.getItem('user')).result._id;
        const baseUrl = `${process.env.REACT_APP_BACKEND}/api/accomodation/update/${params.id}`;
        const form = document.forms.accForm
        const data = {
            hotelId:form.hotelId.value,
            roomPrice:form.roomPrice.value,
            accType:form.accType.value,
            accTypeCharges:0,
            addService:'',
            addCharges:0,
            people:form.people.value,
            userID:userID
        };

        const res = await axios.put(baseUrl, data);
        if(res.status===200){
            resetStorage()
            navigate('/accomodations')
        }
    }

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Update Accomodations</h1>
                            </div>
                            <div className="col-sm-6">

                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <form name='accForm' onSubmit={submitAccomodations}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                    </div>

                                    <div className="card-body">
                                            <div className='row'>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Accomodation Name</label>
                                                    <select className="form-control" value={result.hotelId} name='hotelId'>
                                                    {
                                                        hotels.map(n => <option key={n._id} value={n._id}>{n.name}</option>)
                                                    }
                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">People</label>
                                                    <select className="form-control" value={result.people} name='people'>
                                                        <option value={''}>Please Select Person</option>
                                                    {
                                                        peoples.map(n => <option key={n} value={n}>{n} Person</option>)
                                                    }
                                                    </select>
                                                </div>
                                                
                                            </div>
                                            <div className='row'>
                                                <select className="form-control" value={result.accType} name='accType'>
                                                    {
                                                        Object.keys(roomTypes).map(n => <option key={n} value={n}>{n}</option>)
                                                    }
                                                </select>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Price</label>
                                                    <input type="text" className="form-control" id="roomPrice" value={result.roomPrice} name='roomPrice' placeholder="Price"/>
                                                </div>
                                                {/* <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Price</label>
                                                    <input type="text" className="form-control" id="accTypeCharges" name='accTypeCharges' value={result.accTypeCharges} placeholder="Type Charges"/>
                                                </div> */}
                                            </div>
                                            {/* <div className='row'>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Additional Services</label>
                                                    <input type="text" className="form-control" id="addService" name='addService' value={result.addService} placeholder="Additional Services"/>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Additional Charges</label>
                                                    <input type="text" className="form-control" id="addCharges" name='addCharges' value={result.addCharges} placeholder="Add Charges"/>
                                                </div>
                                            </div> */}
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary">Update</button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    </form>
                </section>
            </div>
        </div>
    )
}

export default UpdateAccomodations;
