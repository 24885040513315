import React, { Component } from 'react';
import "../../Home/Home.css"
import "./index.css"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import sunset from '../../../assets/sunset.jpg'
import AccommodationForm from '../AccommodationForm';
// import CampsForm from '../CampsForm';

const Desc = ({handleCart, cart}) => {

    const caroselStyle = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      },
    };

    const imgStyle = {
        height: '85px',
    }
    const cStyle = {
        height: '250px',
    }

    
    // if(lessonCategory.length === 0)
    //     return <></>

    return (
        <>
            <section className='Desc-sec py-5'>
                <div class="container">
                    <div className='row'>
                    <Carousel responsive={caroselStyle}>
                        <div><img src={sunset} style={cStyle} alt="" srcset="" /></div>
                        <div><img src={sunset}  style={cStyle} alt="" srcset="" /></div>
                        <div><img src={sunset}  style={cStyle} alt="" srcset="" /></div>
                        <div><img src={sunset}  style={cStyle} alt="" srcset="" /></div>
                    </Carousel>;

                    </div>

                    <div className='row white-frame'>
                        <div className='col-lg-6'>
                        <div className='title py-4'>
                            <h1>DESCRIPTION</h1>
                        </div>
                        <p className='desc-subhead'>
                        Looking for a surf holiday that doesn’t break the bank? Here at Sidi Kaouki Surf Station we tailor make surf packages to suit all budgets! Our budget package offers you an all-inclusive stay complete with breakfast, lunch and dinner everyday, daily surf tuition from our expert teachers and board and wetsuit hire for the week, proving that the perfect surf vacation needn’t be expensive.
                            <br/>
                            <br/>

                            You will stay in a centrally located hostel in dorm-style rooms that sleep  up to a maximum of 5 people and include a shared bathroom. The hostel is simple but has everything you could need for your stay including a shared lounge and rooftop where you can meet and hang out with other surfers!
                            <br/>
                            <br/>

                            You will also be spending lots of time at the Surf Station which boasts a restaurant and terrace overlooking the ocean where all meals will be served. Vegetarian and vegan options are available. On the ground floor is the surf shop that houses all surfing equipment and gear and includes a changing area and lockers where you can secure your belongings when you’re out on the waves.  Come join us for the perfect surf getaway on Morocco’s beautiful Atlantic coast!
                        </p>
                        
                        </div>
                        <div className='col-lg-6'>
                        <div className='title py-4'>
                            <h1>What’s included?</h1>            
                        </div>
                        <p className='desc-subhead'>
                                ► 7 nights hostel accommodation <br/>
                                ► Breakfast, lunch, and dinner at the Surf Station restaurant (7 days)<br/>
                                ► Daily 2-hour surf lessons (6 days)<br/>
                                ► Surfboard and wetsuit hire (6 days)<br/>
                            </p>
                            <AccommodationForm type={'6402fd477e80619a6a1bb621'} title={'Marabout'} handleCart={handleCart} cart={cart}/>
                        </div>
                    </div>



                </div>
            </section>
        </>
    );
}

export default Desc;
