import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate,useParams } from "react-router-dom";
import { resetStorage } from '../../../utils/helper';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { FormCheck } from 'react-bootstrap';
import { date_format } from '../../../utils/config';
import moment from 'moment';



const UpdateCamps = () => {
   

        const [campsname, setPName] = useState("");
        const [people, setPeople] = useState("");
        const [accomodation, setAccomodation] = useState("");
        const [price, setPrice] = useState("");
        const [name, SetAName] = useState([]);
        const params = useParams();
        const navigate = useNavigate();
        const [isActive, setIsActive] = useState(true);
        let [campDate,setCampDate] = useState({
            startDate:new Date(),
            endDate:new Date(),
        })

        useEffect(()=>{
            getCampsDetails();
        },[])
    
        const getCampsDetails= async()=>{
            console.warn(params)
            let result= await fetch(`${process.env.REACT_APP_BACKEND}/api/update-camps/${params.id}`);
            result= await result.json();
            setPName(result.campsname);
            setPeople(result.people);
            setAccomodation(result.accomodation);
            setPrice(result.price);
            console.log(result.campDate);
            setCampDate({startDate :moment(result.campDate.startDate),endDate:moment(result.campDate.endDate)});
            setIsActive(result.isActive);
        }
    
        const Updatecamps= async () =>{

            const userID = JSON.parse(localStorage.getItem('user')).result._id;
           const baseUrl = `${process.env.REACT_APP_BACKEND}/api/update-camps/${params.id}`;
           const data = {
                campsname:campsname,
                people:people,
                accomodation:accomodation,
                price:price,
                userID:userID,
                isActive:isActive,
                campDate:{startDate : campDate.startDate,endDate : campDate.endDate}
           };

           const result = await axios.put(baseUrl, data);
           if(result.status===200){
                //resetStorage()
                navigate('/camps')
            }
       }



       useEffect(() => {
        getAccomodations();
      }, [])
    
      const getAccomodations = async () => {
        let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/hotel/getAll`);
        result = await result.json();
        SetAName(result);
      }

    const handleEvent = (event, picker) => {
        setCampDate(picker)
    }

    const handleCallback = (start, end, label) => {
        //console.log(start, end, label);
    }



    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Update Camps</h1>
                            </div>
                            <div className="col-sm-6">

                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                    </div>

                                    <div className="card-body">
                                        <div className='row'>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Camps Name</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Name" value={campsname} onChange={e => setPName(e.target.value)} />
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">People</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Name" value={people} onChange={e => setPeople(e.target.value)} />
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label>Accomodation</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) => setAccomodation(e.target.value)}
                                                    >
                                                        {name.map((accomodation) => (
                                                        <option key={accomodation._id} value={accomodation._id}>
                                                            {accomodation.name}
                                                        </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Price</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Name" value={price} onChange={e => setPrice(e.target.value)} />
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Camp Date</label>
                                                    <DateRangePicker 
                                                        format={date_format} 
                                                        onEvent={handleEvent} 
                                                        onCallback={handleCallback}
                                                        startDate={campDate.startDate}
                                                        endDate={campDate.endDate}
                                                        >
                                                        <input />
                                                    </DateRangePicker>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <FormCheck
                                                        type={'checkbox'}
                                                        id={`default-${'checkbox'}`}
                                                        label={`Active`}
                                                        name={'isActive'}
                                                        onChange={() => setIsActive(!isActive)}
                                                        checked={isActive}
                                                    />
                                                    {/* <input type="checkbox" onChange={() => setIsActive(!isActive)} className="form-control" id="isActive" name='isActive' checked={isActive} /> */}
                                                </div>
                                            </div>
                                        
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" onClick={Updatecamps} className="btn btn-primary">Save</button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>
            </div>
        </div>
    )
}

export default UpdateCamps;
