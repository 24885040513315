import React, { Component , useState, useEffect } from 'react';
import "../Home/Home.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';

import icon from "../../assets/blog-icon.webp"
import blog1 from "../../assets/blog-img.jpg"
import read from "../../assets/read.png"
import { Link } from 'react-router-dom';



const Restaurant = () => {
    const [name, SetName] = useState([]);

    useEffect(() => {
      getAccomodations();
    }, [])
  
    const getAccomodations = async () => {
      let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-blog`);
      result = await result.json();
      SetName(result);
    }
  
    const imgStyle = {
        width: '80px',

    }
    
    const imgblog = {
        width: '380px',

    }
    const imgread = {
        width: '180px',
        display: 'table',
        margin: '0 auto',

    }
    return (
        <>
            <section className='home-sec-7 py-5'>
                <div class="container">
                  
                <div className='row' id='blog'>
                        <div className='title py-4'>
                            <img src={icon} style={imgStyle} /><h1>BLOG</h1>
                        </div>
                    </div>
                    <div className='row my-5'>
                    {name.map((item, index) => (
                        
                        <div className='col-lg-4'>
                        <div className='blog-box'>
                        <img src={`${process.env.REACT_APP_BACKEND}/uploads/blogs/${item.document}`} style={imgblog} />
                            <h1 className='blog-title text-center'>{item.title}</h1>
                            <Link to={"/blog-detail/" + item._id}>
                            <img src={read} style={imgread}/></Link>
                        </div>
                        </div>
                    
                    ))}
                    
                   
                  </div>
                    <div className='row'>
                        <div className='col-lg-4 mt-2'>
                                <a href='#' className='see-direction'>GO TO BLOG</a>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    );
}

export default Restaurant;
