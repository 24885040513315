import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../Home/Home.css"
import { FaInstagram } from 'react-icons/fa';
import InstaFeeds from '../../Components/Insta/InstaFeeds';

const Instagram = () => {
    const imgStyle = {
        height: '85px',
    }
    //const access_token = process.env.REACT_APP_INS_TOKEN
    const access_token = 'IGQVJXZAjdTckRqY0RyRTdUeEZAXOE12czN6VzhJdzdGZA0hNUG90SjVrUXBoTnprUFF1NGRPSVRxMGdaTkQzT1UySTVmaG5zQWhYaDR6UGczYlFmZAU9wR2FqTmxmaVNVUElJZAFZARcWhrWWlHMS1OcWk2SAZDZD'
    // const cors = require('cors');
    // const corsOptions ={
    // origin:'http://localhost:3000', 
    // credentials:true,            //access-control-allow-credentials:true
    // optionSuccessStatus:200
    // }
    // App.use(cors(corsOptions));
    const [posts, setPosts] = useState([]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         // Replace YOUR_ACCESS_TOKEN with your actual Instagram access token
    //         const result = await axios(
    //             'https://api.instagram.com/v1/users/self/media/recent/?access_token=IGQVJWcXRxNTRPOThkalZAXUTF2OVBQX21GWGhTbmNNb3pJR2xFemRZAZAGlRZAi1LUmdJMHVpTTdVMjI1d3E5SHdreG9fR2hwRG5DNTNvS2ZABMVFFZAVpwbnRrWVhhSmVPME13bFlodzdoeW9rSTZAWQlJldgZDZD'
    //         );
    //         setPosts(result.data.data);
    //     };
    //     fetchData();
    // }, []);
    return (
        <>
                <section className='blue-bg-strip'></section>

            <section className='home-sec-6 py-5'>
                <div class="container">
                    
                    <div className='row'>
                        <div className='insta-sec-title text-center py-5'>
                            <FaInstagram className="black-bg" /><span>SIDIKAOUKI.SURFSTATION</span>
                        </div>
                        <div className="row">
                            <InstaFeeds token={access_token} limit={12}/>
                        </div>
                        <div className='row py-5'>
                            {/* <div className='col-lg-6 text-right'>
                                <a href='#' className='load-more-insta'>LOAD MORE</a>
                            </div> */}
                            <div className='col-lg-12'>
                                <a target={'_blank'} className='folow-insta' href='https://www.instagram.com/sidikaouki.surfstation/'><FaInstagram className="insta-bg-icon" />FOLLOW ON INSTAGRAM</a>
                            </div>


                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    );
}

export default Instagram;
