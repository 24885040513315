import axios from 'axios';
import { addInCart, setDate, triggerNativeEventFor } from '../../utils/helper';
import React, { useState, useEffect, useRef } from 'react';
import { DatePicker } from 'antd';
import { date_format } from '../../utils/config';
import { toast } from 'react-hot-toast';

const PackageForm = ({handleCart,cart,type,title}) => {
    const [result,setResult] = useState([])
    const [peoples,setPeoples] = useState([]);
    const [people,setPeople] = useState(1);
    const [accoumodationType,setAccomodationType] = useState([]);
    const [accoumodationTypeList,setAccomodationTypeList] = useState([]);
    const [accomodation,setAccomodation] = useState("")
    const [isAccInc,setIsAccInc] = useState(false)
    const [amount,setAmount] = useState(0)
    const peopleRef = useRef()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/api/getPackages/${type}`).then(resp => {
            setResult(resp.data.slice());
        });
    },[])

    useEffect(() => {
        const getAccomodations = async () => {
            let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/hotel/getAll`);
            result = await result.json();
            setAccomodationTypeList(result);
          }
        getAccomodations();
    }, [])
    
    useEffect(() => {
        if(result.length>0){
            const list = result.map(x => x.people)
            setPeoples([...new Set(list)])
            triggerNativeEventFor(peopleRef.current,{ event: 'change', value: list[0]})
        }
    },[result])

    useEffect(() => {
        if(people > 0){
            let data;
            if(accomodation && people) 
                data = result.find(x => x.people == people && accomodation == x.accomodationType);
            else
                data = result.find(x => x.people == people);

            if(data){
                setIsAccInc(data.accomodation == 'yes' ? true : false);
                setAmount(data.price);
            }
        }else{
            triggerNativeEventFor(peopleRef.current,{ event: 'change', value: 0})
        }
    },[people,peoples,accomodation])

    useEffect(() => {
        if(isAccInc){
            const list = []
            result.filter(x => x.people == people).map(a => {
                const li = accoumodationTypeList.find(z => z._id == a.accomodationType)
                list.push(li)
            });
            setAccomodationType(list)
        }else{
            setAccomodationType([])
        }
        //setAccomodationType()
    },[people,isAccInc])

    const handlePeople = (e) => {
        setPeople(e.target.value)
    }

    const handleAccomodotion = (e) => {
        setAccomodation(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = document.forms.packageForm
        if(amount <= 0){
            toast.error('please select package')
            return;
        }else if(form.packageStartDate.value === undefined || form.packageStartDate.value === ""){
            toast.error('please select package date!.')
            return;
        }
        const details = 
            {
                category:type,
                title:title,
                people:form.people.value,
                accomodationType:form.accomodationType.value,
                campStartDate:setDate(form.packageStartDate.value),
                amount:amount
            }
        const itemInCart = await addInCart(details,type);
        if(itemInCart.status){
            handleCart([...cart,details])
            toast.success('Item successfully add in cart.', {
                icon: '👏',
              })
        }else{
            toast.error(itemInCart.message)
        }
    }

    return(
        <form name='packageForm' onSubmit={handleSubmit}>
            <div className="form-group">
                <label>No of people</label>
                <select className="form-control" ref={peopleRef} name='people' onChange={handlePeople}>
                    <option value={''}>Please Select Person</option>
                {peoples.length > 0 &&
                    peoples.map(n => <option key={n} value={n}>{n} Person</option>)
                }
                </select>
            </div>
            {isAccInc &&
                <div className="form-group">
                    <label>ACCOMODATION</label>
                    <select className="form-control" onChange={handleAccomodotion} name='accomodationType'>
                    <option value={''}>Select Option</option> 

                        {accoumodationType.map((accomodation) => (
                        <option key={accomodation._id} value={accomodation._id}>
                            {accomodation.name}
                        </option>
                        ))}
                    </select>
                </div>
            }
            <div class="form-group">
            <label>Starting Date:</label>
                <DatePicker
                    name="packageStartDate"
                    format={date_format}
                />
                {/* <div class="input-group date" id="datebpkg" data-target-input="nearest">
                    <input type="text" name="packageStartDate" class="form-control datetimepicker-input" data-target="#datebpkg" />
                    <div class="input-group-append" data-target="#datebpkg" data-toggle="datetimepicker">
                        <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                    </div>
                </div> */}
            </div>
            <div class="form-group">
            <label>Total Amount: <span className='yellow-bubble'>€{amount}</span></label>
            <p> <b> This Will be the  price  of  whole inclusive Package</b></p>

            </div>
            <button type="submit" className="btn book-now-btn"> <i className='fas fa-suitcase px-2'></i>ADD TO YOUR TRAVEL</button>
        </form>
    )
}

export default PackageForm;