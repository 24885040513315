import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";


import '../index.css'
import { categories, durationList, entityList, peoples } from '../../utils/config';

const NewEntity = () => {
        const [entityName, setEntityName] = useState("Surf");
        const [value, setValue] = useState("Surf");
        const [price, setPrice] = useState(0);
        const [duration, setDuration] = useState("1hr");
        const [increment, setIncrement] = useState(1);
        const [discount, setDiscount] = useState(0);
        const [category, setCategory] = useState("rentals");
        const [people, setPeople] = useState(1);
        const [type, setType] = useState("Pro");
        const [isSuccess, setIsSuccess] = useState(false);
        let [displayForm,setDisplayForm] = useState({});
        const boardTypeList = {'soft':'Soft','advanced':'Advanced','pro':'Pro','rental':'Rental','gearstorage':'Gear Storage'}
        
        const navigate = useNavigate();

    const submitEntity= async () =>{

        const userID = JSON.parse(localStorage.getItem('user')).result._id;
        const baseUrl = `${process.env.REACT_APP_BACKEND}/api/new-entity`;
        const data = {
            name:entityName,
            value:value,
            price:price,
            people:(people === undefined || people === 0 ? 1 : people),
            userID:userID,
            increment:increment,
            discount:discount, 
            category:category,
            duration:duration,
            type:type,
        };

        const result = await axios.post(baseUrl, data);
        if(result.status===200){
            navigate('/entities')
        }
    }

    const handleCategory = (e) => {
        if(e.target.value == 'AIRPORTS'){
            setDisplayForm({
                name:true,
                value:true,
                price:true,
                people:true,
            })
        }else if(e.target.value == 'LESSONS'){
            setDisplayForm({
                name:true,
                value:true,
                price:true,
                people:true,
                increment:true,
                discount:true,
            })
        }else if(e.target.value == 'RENTALS'){
            setDisplayForm({
                name:true,
                value:true,
                price:true,
                people:true,
                duration:true,
                type:true,
            })
        }
        else if(e.target.value == 'accommodation'){
            setDisplayForm({
                name:true,
                value:true,
                price:true,
                people:true,
                increment:true,
                type:true,
            })
        }
        setCategory(e.target.value)
    }

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Add New Entities</h1>
                            </div>
                            <div className="col-sm-6">

                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                    </div>

                                    <div className="card-body">
                                        <div className='row'>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Category</label>
                                                    <select className="form-control" id="type" onChange={handleCategory}>
                                                        {Object.keys(categories).map((x,i) => <option value={x}>{categories[x]}</option>)}
                                                    </select>
                                                </div>
                                                {displayForm['name'] && 
                                                    <div className="form-group col-lg-6">
                                                        <label htmlFor="exampleInputEmail1">Entity Name</label>
                                                        <select className="form-control" id="type" onChange={(e) => setEntityName(e.target.value)}>
                                                            {Object.keys(entityList).map((x,i) => <option value={x}>{entityList[x]}</option>)}
                                                        </select>
                                                    </div>
                                                }
                                                {displayForm['value'] && 
                                                    <div className="form-group col-lg-6">
                                                        <label htmlFor="exampleInputEmail1">Value</label>
                                                        <select className="form-control" id="type" onChange={(e) => setValue(e.target.value)}>
                                                            {Object.keys(entityList).map((x,i) => <option value={x}>{entityList[x]}</option>)}
                                                        </select>
                                                    </div>
                                                }
                                                {displayForm['price'] &&
                                                    <div className="form-group col-lg-6">
                                                        <label htmlFor="exampleInputEmail1">Price</label>
                                                        <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Price" value={price} onChange={e => setPrice(e.target.value)} />
                                                    </div>
                                                }
                                                {displayForm['people'] &&
                                                    <div className="form-group col-lg-6">
                                                        <label htmlFor="exampleInputEmail1">People</label>
                                                        <select className="form-control" onChange={e => setPeople(e.target.value)}>
                                                            <option value={''}>Please Select Person</option>
                                                        {
                                                            peoples.map(n => <option key={n} value={n}>{n} Person</option>)
                                                        }
                                                        </select>
                                                    </div>
                                                }
                                                {displayForm['increment'] &&
                                                    <div className="form-group col-lg-6">
                                                        <label htmlFor="exampleInputEmail1">Increment</label>
                                                        <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Increament" value={increment} onChange={e => setIncrement(e.target.value)} />
                                                    </div>
                                                }
                                                {displayForm['discount'] &&
                                                    <div className="form-group col-lg-6">
                                                        <label htmlFor="exampleInputEmail1">Discount</label>
                                                        <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Discount" value={discount} onChange={e => setDiscount(e.target.value)} />
                                                    </div>
                                                }
                                                {displayForm['duration'] &&
                                                    <div className="form-group col-lg-6">
                                                        <label htmlFor="exampleInputEmail1">Duration</label>
                                                        <select className="form-control" id="duration" onChange={(e) => setDuration(e.target.value)}>
                                                            {Object.keys(durationList).map((x,i) => <option value={x}>{durationList[x].title}</option>)}
                                                        </select>
                                                    </div>
                                                }
                                                {displayForm['type'] &&
                                                    <div className="form-group col-lg-6">
                                                        <label htmlFor="exampleInputEmail1">Board Type</label>
                                                        <select className="form-control" id="type" onChange={(e) => setType(e.target.value)}>
                                                            {Object.keys(boardTypeList).map((x,i) => <option value={x}>{boardTypeList[x]}</option>)}
                                                        </select>
                                                    </div>
                                                }
                                            </div>
                                        
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" onClick={submitEntity} className="btn btn-primary">Save</button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>
            </div>
        </div>
    )
}

export default NewEntity;
