import React, { Component , useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import "../Home/Home.css"
import "./index.css"
import wave from "../../assets/wave.png"

const Intro = () => {
    const imgStyle = {
        width: '500px',

    }
    const [title, setName] = useState("");
    const [desc, setdesc] = useState('');
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const params = useParams();


    const getTeamDetails = async () => {
        console.warn(params);
        let result= await fetch(`${process.env.REACT_APP_BACKEND}/api/get-blog/${params.id}`);
        result = await result.json();
        setName(result.title);
        setdesc(result.desc);        
        setFileName(result.document)
        
      };
    
      useEffect(() => {
        getTeamDetails();
      }, []);

    return (
        <>

            <div class="jumbotron jumbotron-fluid sidi-hero hero-sec-one accomodations-hero">
                <div class="container">
                    <div className='sidi-hero-slider'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <h1 class="text-black">{title}</h1>
                                <img src={wave} style={imgStyle} />
                                {/* <div class="row">
                                    <div class="col-lg-2">
                                        <h3>RENTALS</h3>
                                        <div class="prices-dot-inner"></div>
                                    </div>
                                    <div class="col-lg-2">
                                    <h3>LESSONS</h3>
                                        <div class="prices-dot-inner"></div>
                                    </div>
                                     <div class="col-lg-2">
                                    <h3>PACKAGES</h3>
                                    <div class="prices-dot-inner"></div>

                                    </div>
                                   <div class="col-lg-2">
                                    <h3>CAMPS</h3>
                                    <div class="prices-dot-inner"></div>

                                    </div>
                                     <div class="col-lg-2">
                                    <h3>ACCOMODATION</h3>
                                    <div class="prices-dot-inner"></div>

                                    </div>
                                    <div class="col-lg-2">
                                    <h3>TRANSFERS</h3>

                                    </div>
                                
                                    


                                </div> */}
                            </div>
                            

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Intro;
