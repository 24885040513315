import React, { Component } from 'react';
import "../Home/Home.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import camel from "../../assets/camel2.webp"
import tree from "../../assets/three-trees.webp"
import iconcustom2 from "../../assets/Sidi-Gallery.webp"
import p1 from "../../assets/about-portfolio-1.png"
import p2 from "../../assets/about-portfolio-2.png"
import p3 from "../../assets/about-portfolio-3.png"
import p4 from "../../assets/about-portfolio-4.png"
import p5 from "../../assets/about-portfolio-5.png"
import p6 from "../../assets/about-portfolio-6.png"



const Gallery = () => {
    const imgStyle = {
        height: '85px',

    }

    const styles = {
        width: '100%',
    }
    return (
        <>
                <section className='yellow-bg-strip'></section>

            <section className='about-sec-4 py-5'>
                <div class="container">
                    <div className='row'>
                        <div className='col-lg-6'>
                            <img src={tree}/>
                        </div>

                        <div className='col-lg-6'>
                            <img src={camel} />
                        </div>

                    </div>
                    <div className='row' id='gallery'>
                        <div className='py-5'>
                            <img src={iconcustom2} />
                        </div>                        
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <img src={p1} className='abt-gallery' style={styles}/>
                        </div>
                        <div className='col-lg-4'>
                            <img src={p2} className='abt-gallery' style={styles}/>
                        </div>
                        <div className='col-lg-4'>
                            <img src={p3} className='abt-gallery' style={styles}/>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-lg-4'>
                            <img src={p4} className='abt-gallery' style={styles}/>
                        </div>
                        <div className='col-lg-4'>
                            <img src={p5} className='abt-gallery' style={styles}/>
                        </div>
                        <div className='col-lg-4'>
                            <img src={p6} className='abt-gallery' style={styles}/>
                        </div>
                    </div>
                    <div className='row my-5'>
                        <div className='col-lg-4 mt-2'>
                                {/* <a href='#' className='see-direction'>LEARN MORE</a> */}
                        </div>
                    </div>
                    
                    
                </div>
            </section>
        </>
    );
}

export default Gallery;
