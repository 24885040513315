import React, { Component , useEffect, useRef, useState } from 'react';
import "../Home/Home.css"
import "../Prices/Prices.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import A1 from "../../assets/Reballi-Riads.webp"
import A2 from "../../assets/Beach-Abdla.webp"
import A3 from "../../assets/Windy.webp"
import A4 from "../../assets/Marabout.webp"
import A5 from "../../assets/Feru.webp"
import Rating from 'material-ui-rating'

import icon from "../../assets/accomodation-icon.png"
import learn from "../../assets/learn_more.webp"
import { Link } from 'react-router-dom';

const Accomodation = () => {
    const ratings = [5, 4, 4, 3, 2];

    const accomodationsRef = useRef()
    const imgStyle = {
        height: '85px',

    }
    const campfeatured = {
        width: '100%',
    }

    const [name, SetName] = useState([]);

  useEffect(() => {
    getAccomodations();
  }, [])

  const getAccomodations = async () => {
    let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/hotel/getAll`);
    result = await result.json();
    SetName(result);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
        if (window.location.hash === "#accomodations") {
            if(accomodationsRef.current){
                accomodationsRef.current.scrollIntoView();
            }
        }
        }, 1500);
    return () => clearTimeout(timer);
}, []);

    return (
        <>
                            <section className='blue-bg-stripss'></section>

            <section className='accomodation-sec-3 py-5' id='accomodations' ref={accomodationsRef}>
                <div class="container">

                    <div className='row'>
                        <div className='title'>
                            <img src={icon} style={imgStyle} /><h1>ACCOMODATION</h1>
                        </div>
                    </div>

                    <div className='row'>

                    {name.map((item, index) => (
                        <div className='col-lg-4'>
                            <div className='p-box'>
                                <img className='acom-f' src={`${process.env.REACT_APP_BACKEND}/uploads/accomodations/${item.document}`} style={campfeatured} />
                                <h3>{item.name}</h3>
                                <Rating
                                     value={ratings[index]}
                                     max={5}
                                    readOnly
                                />
                                <div className='p-box-desc'>
                                    <p>{item.excerpt}
                                    </p>
                                </div>
                                <Link to={"/accomodation-detail/" + item._id}>
                                <img src={learn} /></Link>
                            </div>
                        </div>

                    ))}
                                                    
                        

                    </div>
                    
                </div>
            </section>
        </>
    );
}

export default Accomodation;
