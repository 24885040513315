import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import '../index.css'
import { Editor } from "@tinymce/tinymce-react";
// import { DropzoneArea } from 'react-dropzone';
import { DropzoneArea } from 'material-ui-dropzone';


const NewCamps = () => {
    const [heading, setHeading] = useState('');
    const [subheading, setSubheading] = useState('');
    const [desc, setDesc] = useState('');
    const [milestonetitle, setMilestoneTitle] = useState('');
    const [milestonedesc, setMilestoneDesc] = useState('');
    const [milestonesectitle, setMilestoneSecTitle] = useState('');
    const [milestonesecdesc, setMilestoneSecDesc] = useState('');
    const [buttonlink, setButtonLink] = useState('');
    const [offeronetitle, setOfferOneTitle] = useState('');
    const [offeronedesc, setOfferOneDesc] = useState('');
    const [buttonone, setButtonOne] = useState('');
    const [offersectitle, setOfferSecTitle] = useState('');
    const [offersecdesc, setOfferSecDesc] = useState('');
    const [buttontwo, setButtonTwo] = useState('');
    const [offerthirdtitle, setOfferThirdTitle] = useState('');
    const [offerthirddesc, setOfferThirdDesc] = useState('');
    const [buttonthird, setButtonThird] = useState('');
    const [secondheading, setSecondHeading] = useState('');
    const [seconddesc, setSecondDesc] = useState('');
    const [secondbutton, setSecondButton] = useState('');
    


        const navigate = useNavigate();
        
       
    const submitAccomodations= async () =>{
        const userID = JSON.parse(localStorage.getItem('user')).result._id;
        const baseUrl = `${process.env.REACT_APP_BACKEND}/api/hotel/create`;
        const data = {
            heading: heading,
            subheading: subheading,
            desc: desc,
            milestonetitle: milestonetitle,
            milestonedesc: milestonedesc,
            milestonesectitle: milestonesectitle,
            milestonesecdesc: milestonesecdesc,
            buttonlink: buttonlink,
            offeronetitle: offeronetitle,
            offeronedesc: offeronedesc,
            buttonone: buttonone,
            offersectitle: offersectitle,
            offersecdesc: offersecdesc,
            buttontwo: buttontwo,
            offerthirdtitle: offerthirdtitle,
            offerthirddesc: offerthirddesc,
            buttonthird: buttonthird,
            secondheading: secondheading,
            seconddesc: seconddesc,
            secondbutton: secondbutton,
            userID:userID
        };

        const result = await axios.put(baseUrl, data);
        if(result.status===200){
            navigate('/accomodations')
        }
    }

    // const submitAccomodations= async (e) =>{     
    //     e.preventDefault();
 
    //     const userID = JSON.parse(localStorage.getItem('user')).result._id;
    //     const baseUrl = `${process.env.REACT_APP_BACKEND}/api/camp/create`;
    //     const form = document.forms.accForm

    //     const data = { 
    //         name:form.name.value,
    //         excerpt:form.excerpt.value,
    //         description:description,
    //         whatsincluded:whatsincluded,
    //         userID:userID
    //     };
    //     const formData = new FormData();
    //     for (let name in data) {
    //         formData.append(name, data[name]);
    //     }
    //     formData.append("file", file);
    //     formData.append("images", images);

    //     const result = await axios({
    //             method: 'post',
    //             url:baseUrl,
    //             data: formData,
    //             headers: { 'Content-Type': 'multipart/form-data' }
    //         });
    //     //const result = await axios.post(baseUrl, data);
    //     if(result){
    //         console.log(result);
    //         navigate('/campslist')
    //     }
    
    
    // }

    // const saveFile = (e) => {
    //     setFile(e.target.files[0]);
    //     setFileName(e.target.files[0].name);
    // };

    // const saveFiles = (files) => {
    //     setImages(files);
    //   };
    // accType:String,
    // price:Number,
    // people:Number,
    // addService:String,
    // addCharge:Number,

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Add New Page</h1>
                            </div>
                            <div className="col-sm-6">

                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                <form name='accForm' onSubmit={submitAccomodations} enctype="multipart/form-data">    
    <div className="container-fluid">
        <div className="row">
            <div className="col-md-12">
                <div className="card card-primary">
                    <div className="card-header">
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Heading</label>
                                <input type="text" className="form-control" id="heading" placeholder="" value={heading} onChange={e => setHeading(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Subheading</label>
                                <input type="text" className="form-control" id="subheading" placeholder="" value={subheading} onChange={e => setSubheading(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Description</label>
                                <input type="text" className="form-control" id="desc" placeholder="" value={desc} onChange={e => setDesc(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">milestonetitle</label>
                                <input type="text" className="form-control" id="milestonetitle" placeholder="" value={milestonetitle} onChange={e => setMilestoneTitle(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">milestonedesc</label>
                                <input type="text" className="form-control" id="milestonedesc" placeholder="" value={milestonedesc} onChange={e => setMilestoneDesc(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">milestone sec title</label>
                                <input type="text" className="form-control" id="milestonesectitle" placeholder="" value={milestonesectitle} onChange={e => setMilestoneSecTitle(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">milestone sec desc</label>
                                <input type="text" className="form-control" id="milestonesecdesc" placeholder="" value={milestonesecdesc} onChange={e => setMilestoneSecDesc(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">buttonlink</label>
                                <input type="text" className="form-control" id="buttonlink" placeholder="" value={buttonlink} onChange={e => setButtonLink(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">offeronetitle</label>
                                <input type="text" className="form-control" id="offeronetitle" placeholder="" value={offeronetitle} onChange={e => setOfferOneTitle(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">offeronedesc</label>
                                <input type="text" className="form-control" id="offeronedesc" placeholder="" value={offeronedesc} onChange={e => setOfferOneDesc(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">offeronedesc</label>
                                <input type="text" className="form-control" id="offeronedesc" placeholder="" value={buttonone} onChange={e => setButtonOne(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">offeronedesc</label>
                                <input type="text" className="form-control" id="offeronedesc" placeholder="" value={offersectitle} onChange={e => setOfferSecTitle(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">offeronedesc</label>
                                <input type="text" className="form-control" id="offeronedesc" placeholder="" value={offersecdesc} onChange={e => setOfferSecDesc(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">offeronedesc</label>
                                <input type="text" className="form-control" id="offeronedesc" placeholder="" value={buttontwo} onChange={e => setButtonTwo(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">offeronedesc</label>
                                <input type="text" className="form-control" id="offeronedesc" placeholder="" value={offerthirdtitle} onChange={e => setOfferThirdTitle(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">offeronedesc</label>
                                <input type="text" className="form-control" id="offeronedesc" placeholder="" value={offerthirddesc} onChange={e => setOfferThirdDesc(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">offeronedesc</label>
                                <input type="text" className="form-control" id="offeronedesc" placeholder="" value={buttonthird} onChange={e => setButtonThird(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">offeronedesc</label>
                                <input type="text" className="form-control" id="offeronedesc" placeholder="" value={secondheading} onChange={e => setSecondHeading(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">offeronedesc</label>
                                <input type="text" className="form-control" id="offeronedesc" placeholder="" value={seconddesc} onChange={e => setSecondDesc(e.target.value)}/>
                            </div>                           
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">offeronedesc</label>
                                <input type="text" className="form-control" id="offeronedesc" placeholder="" value={secondbutton} onChange={e => setSecondButton(e.target.value)}/>
                            </div>

                            <div className="card-footer">
<button type="submit" className="btn btn-primary">Save</button>
</div>




                            </div>
                            </div>
                            </div></div></div></div>
                            </form>
                </section>
            </div>
        </div>
    )
}

export default NewCamps;


