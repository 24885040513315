
import React from "react"
import Header from "../../Components/Header/Header";
import Blog from "../Home/Blog";
import Footer from "../../Components/Footer/Footer";
import Intro from "./Intro";
import Rentals from "./Rentals";
import Lessons from "./Lessons";
import Packages from "./Packages";
import Camps from "./Camps";
import Transfers from "./Transfers"
import Accomodation from "./Accomodation";



const index=({handleCart,cart})=>{
    return (
        <>
        <Header handleCart={handleCart} cart={cart}/>
        <React.Fragment>
            <Intro/>
            <Rentals handleCart={handleCart} cart={cart}/>
            <Lessons handleCart={handleCart} cart={cart}/>
            <Accomodation/>
            <Transfers handleCart={handleCart} cart={cart}/>
            <Camps/>
            <Packages handleCart={handleCart} cart={cart}/>
           
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index;