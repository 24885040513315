import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate , useParams } from "react-router-dom";


import '../index.css'

const UpdateEntity = () => {
   


        const [name, setName] = useState("");
        const [value, setValue] = useState("");
        const [price, setPrice] = useState("");
        const [people, setPeople] = useState("");
        const [isSuccess, setIsSuccess] = useState(false);

        const navigate = useNavigate();


        const params = useParams();


        useEffect(()=>{
            getEntityDetails();
        },[])
    
        const getEntityDetails= async()=>{
            console.warn(params)
            let result= await fetch(`${process.env.REACT_APP_BACKEND}/api/update-entity/${params.id}`);
            result= await result.json();
            setName(result.name)
            setValue(result.value)
            setPrice(result.price)
            setPeople(result.people)
           
        }
    




        const updateEntitys= async () =>{

            const userID = JSON.parse(localStorage.getItem('user')).result._id;
           const baseUrl = `${process.env.REACT_APP_BACKEND}/api/update-entity/${params.id}`;
           const data = {
               name:name,
               value:value,
               price:price,
               people:people,
               userID:userID,
           };

           const result = await axios.put(baseUrl, data);
           if(result.status===200){
                navigate('/entities')
        }
       }





    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Update Entity</h1>
                            </div>
                            <div className="col-sm-6">

                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                    </div>

                                    <div className="card-body">
                                        <div className='row'>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Entity Name</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Value</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Value" value={value} onChange={e => setValue(e.target.value)} />
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Price</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Price" value={price} onChange={e => setPrice(e.target.value)} />
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">People</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="People" value={people} onChange={e => setPeople(e.target.value)} />
                                                </div>
                                            </div>
                                        
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" onClick={updateEntitys} className="btn btn-primary">Update</button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>
            </div>
        </div>
    )
}

export default UpdateEntity;
