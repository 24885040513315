
import React from "react"
import Header from "../../Components/Header/Header";
import Blog from "../Home/Blog";
import Footer from "../../Components/Footer/Footer";
import Intro from "./Intro";
import Login from "./Login";



const index=()=>{
    return (
        <>
        <Header/>
        <React.Fragment>
            <Intro/>
            <Login/>
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index;