import React, { Component } from 'react';
import "../About/About.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import slide1 from "../../assets/slide-1.png"
import wave from "../../assets/wave.png"

const Intro = () => {
    const imgStyle = {
        width: '500px',

    }
    return (
        <>

            <div class="jumbotron jumbotron-fluid sidi-hero hero-sec-one about-hero">
                <div class="container">
                    <div className='sidi-hero-slider'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <h1 class="text-black">ABOUT US</h1>
                                <img src={wave} style={imgStyle} />
                                <div class="row d-flex justify-content-center">
                                    <div class="col-sm-2  mx-auto">
                                        <a className='pricestop-a' href='/about#station'><h3>STATION</h3></a>
                                        <div class="about-dot-inner"></div>
                                    </div>
                                    <div class="col-sm-2  mx-auto">
                                    <a className='pricestop-a' href='/about#team'><h3>TEAM</h3></a>
                                        <div class="about-dot-inner"></div>
                                    </div>
                                    <div class="col-sm-2  mx-auto">
                                   <a className='pricestop-a' href='/about#condition'><h3>CONDITIONS</h3></a> 
                                        <div class="about-dot-inner"></div>
                                    </div>
                                    <div class="col-sm-2  mx-auto">
                                    <a className='pricestop-a' href='/about#surrounding'><h3>SURROUNDING</h3></a>
                                        <div class="about-dot-inner"></div>
                                    </div>
                                    
                                    <div class="col-sm-2  mx-auto">
                                   <a className='pricestop-a' href='/about#gallery'> <h3>GALLERY</h3></a>
                                        <div class="about-dot-inner"></div>
                                    </div>
                                    <div class="col-sm-2  mx-auto">
                                    <a className='pricestop-a' href='/about#blog'><h3>BLOG</h3></a>
                                    </div>
                                    


                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Intro;
