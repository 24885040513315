import React, { Component } from 'react';
import "../Login/Login.css"
import "../Home/Home.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import slide1 from "../../assets/slide-1.png"
import wave from "../../assets/wave.png"

const Intro = () => {
    const imgStyle = {
        width: '500px',

    }
    return (
        <>

            <div class="jumbotron jumbotron-fluid sidi-hero hero-sec-one Login-hero">
                <div class="container">
                    <div className='sidi-hero-slider'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <h1 class="text-black">LOGIN</h1>
                                <img src={wave} style={imgStyle} />
                                
                            </div>
                            <div className='col-lg-6 '>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Intro;
