import React, { Component } from 'react';
import "../Restaurant/Restaurant.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import slide1 from "../../assets/slide-1.png"
import wave from "../../assets/wave.png"

const Intro = () => {
    const imgStyle = {
        width: '500px',

    }
    return (
        <>

            <div class="jumbotron jumbotron-fluid sidi-hero hero-sec-one  about-hero">
                <div class="container">
                    <div className='sidi-hero-slider'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <h1 class="text-black">RESTAURANT</h1>
                                <img src={wave} style={imgStyle} />
                                <div class="row d-flex">
                                    <div class="col-sm-2">
                                        <a className='pricestop-a' href='/restaurant#menu' ><h3 className='text-black'>MENU</h3></a>
                                        <div class="about-dot-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>

                            </div>
                           

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Intro;
