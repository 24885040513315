export const date_format = 'DD-MM-YYYY'
export const peoples = [1,2,3,4,5,6]
export const durationList = {
    '1hr':{title:'1 hr',day:1},
    '2hr':{title:'2 hr',day:1},
    '3hr':{title:'3 hr',day:1},
    '4hr':{title:'4 hr',day:1},
    '5hr':{title:'5 hr',day:1},
    '6hr':{title:'6 hr',day:1},
    '7hr':{title:'7 hr',day:1},
    '8hr':{title:'8 hr',day:1},
    '9hr':{title:'9 hr',day:1},
    '10hr':{title:'10 hr',day:1},
    '1/2d':{title:'1/2 Day',day:1},
    '1d':{title:'1 Days',day:1},
    '2d':{title:'2 Days',day:2},
    '3d':{title:'3 Days',day:3},
    '4d':{title:'4 Days',day:4},
    '5d':{title:'5 Days',day:5},
    '6d':{title:'6 Days',day:6},
    '7d':{title:'7 Days',day:7},
    '8d':{title:'8 Days',day:8},
    '9d':{title:'9 Days',day:9},
    '10d':{title:'10 Days',day:10},
    '11d':{title:'11 Days',day:11},
    '12d':{title:'12 Days',day:12},
    '13d':{title:'13 Days',day:13},
    '14d':{title:'14 Days',day:14},
    '1week':{title:'1 Week',day:7},
}
export const days = {
    '1d':'1 Day',
    '2d':'2 Days',
    '3d':'3 Days',
    '4d':'4 Days',
    '5d':'5 Days',
    '6d':'6 Days',
    '7d':'7 Days',
    '8d':'8 Days',
    '9d':'9 Days',
    '10d':'10 Days',
    '11d':'11 Days',
    '12d':'12 Days',
    '13d':'13 Days',
    '14d':'14 Days',
}
export const surfDays = {
    '1hr':{title:'1 hr',day:1},
    '2hr':{title:'2 hr',day:1},
    '1d':{title:'1 Day',day:1},
    '2d':{title:'2 Day',day:2},
    '3d':{title:'3 Days',day:3},
    '4d':{title:'4 Days',day:4},
    '1week':{title:'1 Week',day:7},
    '2week':{title:'2 Week',day:14},
}

export const supDays = {
    '1hr':'1 hr',
    '2hr':'2 hr',
    '1/2d':'1/2 Day',
    '1d':'1 Day',
    '2d':'2 Days',
    '5d':'5 Days',
    '1week':'1 Week',
    '2week':'2 Week',
}

export const categories = {
    'RENTALS':'Rentals',
    'LESSONS':'Lessons',
    'AIRPORTS':'Transfer'
}

// export const packages = {
//     'budget':'Budget',
//     'family':'Family',
//     'couple':'Couple',
//     'adventure':'Adventure',
//     'luxury':'Luxury'
// }

export const packages = async () => {
    let packages = window.sessionStorage.getItem('packages');
    try{
        packages = JSON.parse(window.sessionStorage.getItem('packages'));
        if(!packages){
            let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/package/getAll`);
            result = await result.json();
            packages = {}
            if(result){
                result.map(x => {
                    packages[x._id] = x.name;
                })
                window.sessionStorage.setItem('packages',JSON.stringify(packages));
            }
        }
    }catch(e){
        let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/package/getAll`);
        result = await result.json();
        packages = {}
        if(result){
            result.map(x => {
                packages[x._id] = x.name;
            })
            window.sessionStorage.setItem('packages',JSON.stringify(packages));
        }
    }
    return packages
}

export const camps = async () => {
    let camps = window.sessionStorage.getItem('camps');
    try{
        camps = JSON.parse(window.sessionStorage.getItem('camps'));
        if(!camps){
            let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/camp/getAll`);
            result = await result.json();
            camps = {}
            if(result){
                result.map(x => {
                    camps[x._id] = x.name;
                })
                window.sessionStorage.setItem('camps',JSON.stringify(camps));
            }
        }
    }catch(e){
        let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/camp/getAll`);
        result = await result.json();
        camps = {}
        if(result){
             result.map(x => {
                camps[x._id] = x.name;
            })
            window.sessionStorage.setItem('camps',JSON.stringify(camps));
        }
    }
    return camps
}

export const entityList = {
    'Surf':'Surf',
    'Kite':'Kite',
    'Windsurf':'Windsurf',
    'Kitesurf':'Kitesurf',
    'SUP':'SUP',
    'Agadir':'Agadir',
    'Marakash':'Marakash',
    'Casablanca':'Casablanca',
    'Essaouira':'Essaouira',
}

export const accommodationList = async () => {
    let accommodations = window.sessionStorage.getItem('accommodations');
    try{
        accommodations = JSON.parse(window.sessionStorage.getItem('accommodations'));
        if(!accommodations){
            let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/hotel/getAll`);
            result = await result.json();
            accommodations = {}
            if(result){
                result.map(x => {
                    accommodations[x._id] = x.name;
                })
                window.sessionStorage.setItem('accommodations',JSON.stringify(accommodations));
            }
        }
    }catch(e){
        let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/hotel/getAll`);
        result = await result.json();
        accommodations = {}
        if(result){
            result.map(x => {
                accommodations[x._id] = x.name;
            })
            window.sessionStorage.setItem('accommodations',JSON.stringify(accommodations));
        }
    }
    return accommodations
}

// export const accommodationList = {
//     'REBALI RIADS':'REBALI RIADS',
//     'Abdla Apartments':'Abdla Apartments',
//     'WINDY KAOUKI':'WINDY KAOUKI',
//     'MARABOUT':'MARABOUT',
//     'FERU':'FERU'
// }

export const roomTypes = {
    'Standard':'Standard',
    'Seaview':'Seaview',
    'Suite':'Suite',
    'Private Villa':'Private Villa',
    'Private Villa lux':'Private Villa lux',
    'standard garden view 2 rooms':'standard garden view 2 rooms',
    'standard garden view 3 rooms' : 'standard garden view 2 rooms',
    'seaview 2 rooms' : 'seaview 2 rooms',
    'seaview 3 rooms' : 'seaview 3 rooms',
    'Apartment' : 'Apartment',
    'Garden View' : 'Garden View'
}

export const SIDI_DICTIONARY = {
    amount:'Price €',
    lessonDetail:'Sport',
    people:'Person',
    reservationDate:'Starting Date',
    dateOfArrival:'Date Of Arrival',
    campStartDate:'Starting Date',
    bookingDate:'Booking Date',
    dateOfArrivalAndDeparture:'Date Of Arrival & Departure',
    title:'Type',
    lessonDuration:'Duration',
    duration:'Duration',
    wetsuit:'Wetsuit',
    typeOfBoard:'Type Of Board',
    flightNumber:'Flight',
    tripDetail:'Trip Detail',
    pickupLocation:'Pickup Location',
    accType:' Room  selection',
    days:'Duration',
    accomodationType:'Accommodation Type',
    campDates:'Camp Dates',
}