import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "../Contact/Contact.css"
import "../Home/Home.css"
import "./travel.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import slide1 from "../../assets/slide-1.png"
import wave from "../../assets/wave.png"
import { applyFormat, getCartAmount } from '../../utils/helper';
import { camps, packages,accommodationList, SIDI_DICTIONARY } from '../../utils/config';
import accoIcon from "../../assets/accomodation-icon.png"
import camp from "../../assets/icon-custom3.webp"
import PackageIcon from "../../assets/package-icon.webp"
import transfer from "../../assets/car.png"
import surf from "../../assets/cat-1.png"
import windsurf from "../../assets/cat-2.png"
import kitesurf from "../../assets/cat-3.png"
import sup from "../../assets/cat-4.png"
import lesson from "../../assets/icon-custom3.webp"

const Intro = ({itemInCart,...props}) => {
    const [camp,setCamps] = useState([])
    const [package1,setPackage1] = useState([])
    const [accommodations,setAccommodations] = useState([])
    useEffect(() => {
        getData();
    },[])
    
    const getData = async () => {
        let res = await camps()
        setCamps({ ...res })
        res = await packages()
        setPackage1({ ...res })
        res = await accommodationList()
        setAccommodations({ ...res })
    }

    const imgStyle = {
        width: '500px',
    }
    return (
        <>

            <div class="jumbotron jumbotron-fluid sidi-hero hero-sec-one contact-hero">
                <div class="container">
                    <div className='sidi-hero-slider'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <h1 class="text-black">YOUR TRAVEL PACKAGE</h1>
                                <img src={wave} style={imgStyle} />
                                
                            </div>
                            <div className='col-lg-6 '>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <section className='py-5' >
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 cart-items'>
                        <Table imgStyle={imgStyle} package1={package1} camp={camp} accommodations={accommodations} data={itemInCart}/>
                    
                        </div>
                        </div>
                        <div className='row'>
                    <div className='col-12 checkout-box'>
                    <div className='text-center'><p className='checkout-amount'>SUBTOTAL: €{getCartAmount()}</p></div>
                    
                        <Link to='/checkout'>
                        <button type="submit" class="btn cart-btns">CHECKOUT</button></Link>   
                    </div>
                    </div>
                </div>


            </section>
        </>
    );
}

const Table = (props) => {
    const imgStyle = {
        height: '85px',
      }
    return(
  <>
      <div>
      <div className='row'>
      {
      props.data.map((x,i) => <>
      
      <div className='col-lg-4'>
        {
        Object.keys(x).map((a,n) =>  
            <>
            <div className='box'>
              <div className='box-inner'>
                {a == 'category' && x[a] == 'transfer' && 
                    <img src={transfer} style={imgStyle} />
                }
                {a == 'category' && x[a] == 'lesson' && 
                    <img src={lesson} style={imgStyle} />
                }
                {a == 'category' && Object.keys(props.camp).find(o => o == x[a]) && 
                    <img src={camp} style={imgStyle} />
                }
                {a == 'category' && Object.keys(props.package1).find(o => o == x[a]) && 
                    <img src={PackageIcon} style={imgStyle} />
                }
                {a == 'category' && Object.keys(props.accommodations).find(o => o == x[a]) && 
                    <img src={accoIcon} style={imgStyle} />
                }
                {a == 'category' && x[a] == 'surf' &&  
                    <img src={surf} style={imgStyle} />
                }
                {a == 'category' && x[a] == 'windsurf' &&  
                    <img src={windsurf} style={imgStyle} />
                }
                {a == 'category' && x[a] == 'kitesurf' &&  
                    <img src={kitesurf} style={imgStyle} />
                }
                {a == 'category' && x[a] == 'sup' &&  
                    <img src={sup} style={imgStyle} />
                }
                {a == 'approveByAdmin' || a == 'category' ? <></> : 
                    <>
                    <p className='a'> {SIDI_DICTIONARY[a]}:</p>
                    <p className='b'>{applyFormat(a,x[a])}</p>
                    </>
                }
                </div>
            </div>
            </>
        )
        }
        </div> 
        
        </>
      )
      }
             {/* <div>Total Bill:{getCartAmount()}</div> */}

             </div>
        </div>
        
  
      
        </>
    )
    
  }
export default Intro;
