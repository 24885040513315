import React, { Component } from 'react';
import "../../Home/Home.css"
import "./index.css"
import wave from "../../../assets/wave.png"

const Intro = () => {
    const imgStyle = {
        width: '500px',

    }
    return (
        <>

            <div class="jumbotron jumbotron-fluid sidi-hero hero-sec-one packages-hero">
                <div class="container">
                    <div className='sidi-hero-slider'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <h1 class="text-black">ADVENTURE PACKAGE</h1>
                                <img src={wave} style={imgStyle} />
                                {/* <div class="row">
                                    <div class="col-lg-2">
                                        <h3>RENTALS</h3>
                                        <div class="prices-dot-inner"></div>
                                    </div>
                                    <div class="col-lg-2">
                                    <h3>LESSONS</h3>
                                        <div class="prices-dot-inner"></div>
                                    </div>
                                     <div class="col-lg-2">
                                    <h3>PACKAGES</h3>
                                    <div class="prices-dot-inner"></div>

                                    </div>
                                   <div class="col-lg-2">
                                    <h3>CAMPS</h3>
                                    <div class="prices-dot-inner"></div>

                                    </div>
                                     <div class="col-lg-2">
                                    <h3>ACCOMODATION</h3>
                                    <div class="prices-dot-inner"></div>

                                    </div>
                                    <div class="col-lg-2">
                                    <h3>TRANSFERS</h3>

                                    </div>
                                
                                    


                                </div> */}
                            </div>
                            

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Intro;
