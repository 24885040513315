import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import '../index.css'
import { Editor } from "@tinymce/tinymce-react";
// import { DropzoneArea } from 'react-dropzone';
import { DropzoneArea } from 'material-ui-dropzone';
import { resetStorage } from '../../utils/helper';


const NewPackage = () => {
        const [name, setName] = useState("");
        const [isSuccess, setIsSuccess] = useState(false);
        const [description, setdescription] = useState('');
        const [whatsincluded, setwhatsincluded] = useState('');
        const [excerpt, setexcerpt] = useState('');
        const [file, setFile] = useState();
        const [fileName, setFileName] = useState("");
        const [crousal, setCrousal] = useState();
        const [gallery, setGallery] = useState();


        const navigate = useNavigate();
        
    //     const submitAccomodations= async (e) =>{
    //         e.preventDefault();
    //         const userID = JSON.parse(localStorage.getItem('user')).result._id;
    //         const baseUrl = `${process.env.REACT_APP_BACKEND}/api/hotel/create`;
    //         const form = document.forms.accForm
    //        const data = {
    //            name:form.name.value,
    //            excerpt:form.excerpt.value,
    //            description:description,
    //            whatsincluded:whatsincluded,
    //            userID:userID
    //        };

    //        const result = await axios.post(baseUrl, data);
    //        if(result.status===200){
    //             navigate('/hotels')
    //     }
    // }

    const submitAccomodations= async (e) =>{     
        e.preventDefault();
 
        const userID = JSON.parse(localStorage.getItem('user')).result._id;
        const baseUrl = `${process.env.REACT_APP_BACKEND}/api/package/create`;
        const form = document.forms.accForm

        const data = { 
            name:form.name.value,
            excerpt:form.excerpt.value,
            description:description,
            whatsincluded:whatsincluded,
            userID:userID
        };
        const formData = new FormData();
        for (let name in data) {
            formData.append(name, data[name]);
        }
        formData.append("file", file);
        crousal.map(file => {
            formData.append('crousal', file);
        })

        gallery.map(file => {
            formData.append('gallery', file);
        })

        const result = await axios({
                method: 'post',
                url:baseUrl,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' }
            });
        //const result = await axios.post(baseUrl, data);
        if(result){
            resetStorage()
            navigate('/packageslist')
        }
    }

    const saveFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };

    const saveCrousal = (files) => {
        setCrousal(files);
      };

    const saveGallery = (files) => {
        setGallery(files)
      };

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Add New Package</h1>
                            </div>
                            <div className="col-sm-6">

                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                <form name='accForm' onSubmit={submitAccomodations} enctype="multipart/form-data">    
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                    </div>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Package Name</label>
                                                <input type="text" className="form-control" id="name" placeholder="Name"/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Package Excerpt</label>
                                                <input type="text" className="form-control" id="excerpt" placeholder="excerpt"/>
                                            </div>
                                            <div className="form-group col-lg-6">
                                            <label htmlFor="exampleInputEmail1">Description</label>
                                            <Editor
                                            textareaName="product Description"
                                            value={description} 
                                            //onEditorChange={(e)=>SetDescription(e.target.value)} 
                                            onEditorChange={(newValue, editor) => setdescription(newValue)}
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar: 'undo redo | formatselect | ' +
                                                'bold italic backcolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                        />
                                        </div>
                                        <div className="form-group col-lg-6">
                                        <label htmlFor="exampleInputEmail1">Whats Included</label>

                                        <Editor
                                            textareaName="product Description"
                                            value={whatsincluded} 
                                            //onEditorChange={(e)=>SetDescription(e.target.value)} 
                                            onEditorChange={(newValue, editor) => setwhatsincluded(newValue)}
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar: 'undo redo | formatselect | ' +
                                                'bold italic backcolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="exampleInputEmail1">Featured Image</label>
                                    <input
                                        accept="image/*"
                                        id="thumbnail-upload"
                                        type="file"
                                        onChange={saveFile}
                                    />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="exampleInputEmail1">Carousel images</label>
                                    <DropzoneArea
                                    acceptedFiles={['image/*']}
                                    type="file"
                                    onChange={saveCrousal}
                                    filesLimit={50}
                                    maxFileSize={5000000}
                                    dropzoneText="Drag and drop or click to upload up to 5 images"
                                    />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="exampleInputEmail1">Gallery</label>
                                    <DropzoneArea
                                    acceptedFiles={['image/*']}
                                    type="file"
                                    onChange={saveGallery}
                                    filesLimit={500}
                                    maxFileSize={5000000}
                                    dropzoneText="Drag and drop or click to upload up to 5 images"
                                    />
                                    </div>
                                        </div>
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary">Save</button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    </form>
                </section>
            </div>
        </div>
    )
}

export default NewPackage;
