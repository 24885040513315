import React, { Component } from 'react';
import { Carousel } from 'antd';
import "../Restaurant/Restaurant.css"
import "../Home/Home.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';

import icon from "../../assets/team-copy.webp"
import t1 from "../../assets/res-1.png"
import t2 from "../../assets/res-2.png"
import t3 from "../../assets/res-3.png"
import t4 from "../../assets/res-4.png"

import Four from "../../assets/4.png"
import Five from "../../assets/5.png"
import Six from "../../assets/6.png"
import Seven from "../../assets/7.png"
import Eight from "../../assets/8.png"
import Nine from "../../assets/9.png"
import Ten from "../../assets/10.png"
import Eleven from "../../assets/11.png"
import Twelve from "../../assets/12.png"
import Thirteen from "../../assets/13.png"
import Fourteen from "../../assets/14.png"
import Fifteen from "../../assets/15.png"
import Sixteen from "../../assets/16.png"
import Seventeen from "../../assets/17.png"
import Eighteen from "../../assets/18.png"
import Nineteen from "../../assets/19.png"
import Twenty from "../../assets/20.png"
import Twentyone from "../../assets/21.png"
import Twentytwo from "../../assets/22.png"
import Twentythree from "../../assets/23.png"
import Twentyfour from "../../assets/24.png"
import Twentyfive from "../../assets/25.png"
import Twentysix from "../../assets/26.png"
import Twentyseven from "../../assets/27.png"
import TwentyEight from "../../assets/28.png"
import Twentynine from "../../assets/29.png"
import Thirty from "../../assets/30.png"
import ThirtyOne from "../../assets/31.png"
import Thirtytwo from "../../assets/32.png"
import ThirtyThree from "../../assets/33.png"
import ThirtyFour from "../../assets/34.png"




const Gallery = () => {
    const imgStyle = {
        height: '285px',
    }
    const rowStyle = {
        margin: '0 auto',
    }
  
    return (
        <>
        <section className='about-sec-3 py-5'>
            <div className="container">
                <div className='row'>
                    <div className='py-5'>
                        {/* <img src={icon} style={imgStyle} alt="Team icon" /> */}
                    </div>
                </div>
                
                
                
            </div>
            <div className='row text-center' style={rowStyle}>
                    <div className='col-lg-3'>
                        <img src={t1} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={t2} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={t3} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={t4} style={imgStyle} className='abt-gallery' />
                    </div>
                </div>
                <div className='row text-center py-3' style={rowStyle}>
                    <div className='col-lg-3'>
                    <img src={ThirtyFour} style={imgStyle} className='abt-gallery'/>

                    </div>
                    <div className='col-lg-3'>
                        <img src={Four} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Five} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Six} style={imgStyle} className='abt-gallery'/>
                    </div>
                </div>
                <div className='row text-center py-3' style={rowStyle}>
                    <div className='col-lg-3'>
                        <img src={Seven} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Eight} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Nine} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Ten} style={imgStyle} className='abt-gallery'/>
                    </div>
                </div>
                <div className='row text-center py-3' style={rowStyle}>
                    <div className='col-lg-3'>
                        <img src={Eleven} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Twelve} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Thirteen} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Fourteen} style={imgStyle} className='abt-gallery'/>
                    </div>
                </div>

                <div className='row text-center py-3' style={rowStyle}>
                    <div className='col-lg-3'>
                        <img src={Fifteen} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Sixteen} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Seventeen} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Eighteen} style={imgStyle} className='abt-gallery'/>
                    </div>
                </div>
                
                <div className='row text-center py-3' style={rowStyle}>
                    <div className='col-lg-3'>
                        <img src={Nineteen} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Twenty} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Twentyone} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Twentytwo} style={imgStyle} className='abt-gallery'/>
                    </div>
                </div>
                
                <div className='row text-center py-3' style={rowStyle}>
                    <div className='col-lg-3'>
                        <img src={Twentythree} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Twentyfour} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Twentyfive} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Twentysix} style={imgStyle} className='abt-gallery'/>
                    </div>
                </div>

                <div className='row text-center py-3' style={rowStyle}>
                    <div className='col-lg-3'>
                        <img src={Twentyseven} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={TwentyEight} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Twentynine} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Thirty} style={imgStyle} className='abt-gallery'/>
                    </div>
                </div>
                
                <div className='row text-center py-3' style={rowStyle}>
                    <div className='col-lg-3'>
                        <img src={ThirtyOne} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={Thirtytwo} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        <img src={ThirtyThree} style={imgStyle} className='abt-gallery'/>
                    </div>
                    <div className='col-lg-3'>
                        {/* <img src={ThirtyFour} style={imgStyle} className='abt-gallery'/> */}
                    </div>
                </div>
                
                


                <p className='sec-3-subhead text-center'>In our kitchen, we use locally produced argan oil from a nearby Berber village.</p>
                <div className='text-center'>
                <iframe width="1318" className='abt-gallery' height="741" src="https://www.youtube.com/embed/m5CsH2Q8p0E" title="Moroccan Argan Oil Production" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
        </section>
        <section className='res-scaled'></section>
        </>
    );
}

export default Gallery;
