
import '../index.css'

import React, { useEffect ,useContext } from 'react';
import { RouterContext } from 'react-router';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const auth=localStorage.getItem('user')
  const navigate= useNavigate();
  const logout=()=>{
    localStorage.clear();
    navigate('/login')
  }


  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        {/* <li className="nav-item">
          <a className="nav-link-dash" data-widget="pushmenu" href="#" role="button">
            <i className="fas fa-bars" />
          </a>
        </li> */}
      </ul>
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <a className="nav-link-logout" role="button" onClick={logout}>
            <i className="fas fa-sign-out-alt" aria-hidden="true" />
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Header;

