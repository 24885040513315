import React, { Component, useState, useEffect } from 'react';
import "../Home/Home.css"
import "../Prices/Prices.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import campf from "../../assets/camp-featured.webp"
import tree from "../../assets/tree.png"
import icon from "../../assets/icon-custom3.webp"
import learn from "../../assets/learn_more.webp"
import { Link } from 'react-router-dom';

const Camps = () => {
    const imgStyle = {
        height: '85px',

    }
    const campfeatured = {
        width: '100%',
    }
    const [name, SetName] = useState([]);

    useEffect(() => {
      getAccomodations();
    }, [])
  
    const getAccomodations = async () => {
      let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/camp/getAll`);
      result = await result.json();
      SetName(result);
    }
    return (
        <>
                    <section className='yellow-bg-stripss'></section>

            <section className='offers-sec-3 py-5' id='camps'>
                <div class="container">

                    <div className='row'>
                        <div className='title'>
                            <img src={icon} style={imgStyle} /><h1>CAMPS</h1>
                        </div>
                        <p className='sec-3-subhead'>EXPERIENCE A SPECIAL TIME TOGETHER IN THE WAVES</p>
                        <p className='sec-3-desc'>Shared joy is truly double the joy. Have fun meeting like-minded people and improve your surfing, yoga, kite surfing, windsurfing, SUP or horseriding skills as you learn from the pros. Our attentive coaches will help you to push yourself whilst making you feel at ease in a comfortable and fun environment.

                        </p>
                    </div>

                    <div className='row'>
                    {name.map((item, index) => (
                    <div className='col-lg-3'>
                    <div className='c-box'>
                        <img src={`${process.env.REACT_APP_BACKEND}/uploads/camps/${item.document}`} style={campfeatured}/>
                        <h3>{item.name}</h3>
                        {/* <h6>03.03 – 10.03.2022</h6> */}
                        <div className='c-box-desc'>
                        <p>{item.excerpt}</p>
                        </div>
                        <Link to={"/camp-detail/" + item._id}>
                        <img src={learn}/></Link>
                    </div>
                </div>
                    ))}
                        
                       
                    </div>
                </div>
            </section>
            <section className='sea-view'></section>
        </>
    );
}

export default Camps;
