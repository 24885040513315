
import React, { useEffect, useState } from "react"
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import Intro from "./Intro";
import Desc from "./Desc";
import Gallery from "./Gallery";
import { useParams } from "react-router-dom";

const Main = ({handleCart, cart}) => {
    const params = useParams();
    const [result,setResult] = useState();
    const getTeamDetails = async () => {
        let res= await fetch(`${process.env.REACT_APP_BACKEND}/api/camp/get/${params.id}`);
        res = await res.json();
        setResult(res);
    };
    useEffect(() => {
        getTeamDetails();
    },[])

    if(!result)
        return <></>
    return (
        <>
        <Header handleCart={handleCart} cart={cart}/>
        <React.Fragment>
            <Intro crousal={result.crousal}/>
            <Desc handleCart={handleCart} cart={cart} id={params.id} name={result.name} description={result.description} whatsincluded={result.whatsincluded} result={result}/>
            <Gallery gallery={result.gallery}/>
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default Main;