import React, { Component, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';



const List = () => {
  const [name, SetName] = useState([]);

  useEffect(() => {
    getAccomodations();
  }, [])

  const getAccomodations = async () => {
    let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/camp/getAll`);
    result = await result.json();
    SetName(result);
  }

  
  const DeleteEntity = async (id) => {

    // console.warn(id);
    let result = await axios.delete(`${process.env.REACT_APP_BACKEND}/api/camp/delete/${id}`)
    // result = await result.json();
    if (result) {
        getAccomodations();
    }

  };
 
  // gather unique categories to render as options in the select element


  

  return (
    <div>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>All Camps</h1>
              </div>
              <div className="col-sm-4 float-sm-right">
              </div>
              <div className="col-sm-2 float-sm-right">
                <Link to="/newcamps">
                  <button type="button" class="btn btn-block bg-gradient-primary text-white">
                    <i class="fa fa-plus px-1"></i>
                    Add New</button>
                </Link>
              </div>
            </div>
            
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                  </div>
                  <div className="card-body">
                    <div className="row mb-2">
                      <div className="col-sm-4">
                      <div className="form-group">

                        </div>
                      </div>
                    </div>
                    {name.length > 0 ? (

                      <table id="example1" className="table table-bordered table-striped">

                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Actions</th>

                          </tr>
                        </thead>
                        <tbody>
                          {name.map((item, index) => (
                              <tr key={item._id}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>
                                  {/* <Link to={"/update-c/" + item._id}>
                                    <i className='fas fa-pencil-alt'></i>
                                  </Link> */}
                                  <i className='fas fa-trash-alt px-2' onClick={() => DeleteEntity(item._id)}></i> 
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Action</th>
                          </tr>
                        </tfoot>
                      </table>
                    ) : (
                      <div>No data available</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default List