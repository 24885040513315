import { DatePicker } from "antd";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { date_format } from "../../utils/config";
import { addInCart, setDate, triggerNativeEventFor } from "../../utils/helper";

const Sup = ({data,days,wetsuit,noOfPeople,handleCart, cart}) => {
    const [people,setPeople] = useState(1);
    const [addWetsuit,setAddWetsuit] = useState(false);
    const [durationList,setDurationList] = useState([]);
    const [amount,setAmount] = useState(0);
    const [duration,setDuration] = useState();
    const durationRef = useRef()

    useEffect(() => {
        calculate();
    },[people,addWetsuit])

    useEffect(() => {
        if(durationList.length > 0 && duration){
            triggerNativeEventFor(durationRef.current,{ event: 'change', value: duration})
        }
    },[duration,durationList])

    const calculate = () => {
        const list = data.map(x => {
            const wetsuitPrice = (addWetsuit ? wetsuit*people : 0);
            return {
                'price': (x.price*people)+wetsuitPrice,
                'time':x.duration,
                'value':x.value,
                'wetSuitCharger':wetsuitPrice,
                'name':`${days[x.duration]} (+€${(x.price*people)+wetsuitPrice})`
            }
        })
        setDurationList(list)
    }

    const handleRate = (e) => {
        const price = e.target[e.target.selectedIndex].dataset.price
        setDuration(e.target.value)
        setAmount(price);
    }

    const handlePeople = changeEvent => {
        setPeople(changeEvent.target.value)
    }

    const bookNow = async (e) => {
        e.preventDefault();
        const form = document.forms.sup
        if(amount <= 0){
            toast.error('please select Sup')
            return;
        }else if(form.supDate.value === undefined || form.supDate.value === ""){
            toast.error('please select Sup date!.')
            return;
        }
        
        const details = 
            {
                category:'sup',
                title:'Sup',
                people:form.people.value,
                duration:form.duration.value,
                reservationDate:setDate(form.supDate.value),
                wetsuit:addWetsuit ? 'yes' : 'no',
                amount:amount
            }
        
        const itemInCart = await addInCart(details,details.category);
        if(itemInCart.status){
            handleCart([...cart,details])
            toast.success('Item successfully add in cart.', {
                icon: '👏',
              })
        }else{
            toast.error(itemInCart.message)
        }
    }

    return(
        <form name="sup" onSubmit={bookNow}>
            <div className="row">

                <div className="col-sm-3">

                    <div className="form-group">
                        <label>No of people</label>
                        <select className="form-control" name="people" onChange={handlePeople}>
                            <option key={'11'} value={''}>Please Select Person</option>
                            {
                                noOfPeople.map(n => <option key={n} value={n}>People {n}</option>)
                            }
                        </select>
                    </div>


                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label>Duration</label>
                        <select className="form-control" name="duration" ref={durationRef} onChange={handleRate}>
                        <option key={'11'} value={''}> Select Option </option>
                            {durationList.length > 0 &&
                                durationList.map((a,i) => <option key={i} value={a.time} data-price={a.price} data-duraion={a.time}>{a.name}</option>)
                            }
                        </select>
                    </div>
                    {/* text input */}

                </div>
                <div className="col-sm-3">
                    <div class="form-group">
                        <label>Date:</label><br/>
                        <DatePicker
                            name="supDate"
                            format={date_format}
                        />
                        {/* <div class="input-group date" id="datesup" data-target-input="nearest">
                            <input type="text" name="supDate" class="form-control datetimepicker-input" data-target="#datesup" />
                            <div class="input-group-append" data-target="#datesup" data-toggle="datetimepicker">
                                <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                            </div>
                        </div> */}
                    </div>

                </div>
                <div className="col-sm-3">
                    <div class="form-group">

                    <div class="form-check">
                            <input class="form-check-input" type="checkbox" checked={addWetsuit} onChange={(e) => setAddWetsuit(e.target.checked)}/>
                            <label class="form-check-label">Need Wetsuit</label>
                        </div>

                    </div>

                </div>
            </div>
            <div className="row">
                <div className="col-sm-4">
                    <div class="form-group">
                        <label>Total Sup Amount: <span className="yellow-bubble"> €{amount}</span></label>
                    </div>
                </div>
                <div className="col-sm-4">
                    <button type="submit" className="btn book-now-btn"> <i className='fas fa-suitcase px-2'></i>ADD TO YOUR TRAVEL</button>
                </div>
            </div>
        </form>
    )
}
export default Sup;