import React, { Component } from 'react';
import { Carousel } from 'antd';

import "../Home/Home.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';

import icon from "../../assets/team-copy.webp"
import t1 from "../../assets/team-anas.webp"
import t2 from "../../assets/team-azize.webp"
import t3 from "../../assets/team-hassan.webp"
import t4 from "../../assets/team-lhessone.webp"
import t5 from "../../assets/team-mohamed.webp"
import t6 from "../../assets/team-omar.webp"
import t7 from "../../assets/team-said.webp"
import t8 from "../../assets/team-fadel.webp"
const contentStyle = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};
const Team = () => {
    const imgStyle = {
        height: '85px',
    }
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };
    return (
        <>
        <section className='blue-bg-strip'></section>

        <section className='about-sec-3 py-5'>
            <div className="container">
                <div className='row' id='team'>
                    <div className='py-5'>
                        <img src={icon} style={imgStyle} alt="Team icon" />
                    </div>
                </div>
                <div className='row mx-auto'>
                <Carousel afterChange={onChange}>
                    <div>
                        <img src={t1}/>
                    </div>
                    <div>
                    <img src={t2}/>
                    </div>
                    <div>
                    <img src={t3}/>
                    </div>
                    <div>
                    <img src={t4}/>
                    </div>
                    <div>
                    <img src={t5}/>
                    </div>
                    <div>
                    <img src={t6}/>
                    </div>
                    <div>
                    <img src={t7}/>
                    </div>
                    <div>
                    <img src={t8}/>
                    </div>
                </Carousel>
                </div>
                <div className='row'>
                    <div className='col-lg-4 my-5'>
                        <a href='/contact' className='about-see-direction'>MORE ABOUT THE TEAM</a>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default Team;
