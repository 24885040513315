import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate , useParams } from "react-router-dom";

import { Editor } from "@tinymce/tinymce-react";
// import { DropzoneArea } from 'react-dropzone';
import { DropzoneArea } from 'material-ui-dropzone';

import '../../index.css'

const UpdateCamps = () => {
    const [name, setName] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [description, setdescription] = useState('');
    const [whatsincluded, setwhatsincluded] = useState('');
    const [excerpt, setexcerpt] = useState('');
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [images, setImages] = useState([]);
    const [result, setResult] = useState("");
    const [crousal, setCrousal] = useState();
    const [gallery, setGallery] = useState();
    const [gallaryImages,setGalleryImage] = useState()
    const [crausalImages,setCrausalImages] = useState()

        const navigate = useNavigate();
        const params = useParams();

        useEffect(()=>{
            getAccomodationDetails();
        },[])
    
        const getAccomodationDetails= async()=>{
            console.warn(params)
            let result= await fetch(`${process.env.REACT_APP_BACKEND}/api/camp/get/${params.id}`);
            result= await result.json();
            setName(result.name) 
            setdescription(result.description)
            setwhatsincluded(result.whatsincluded)   
            setexcerpt(result.excerpt);
            const gl = result.gallery.map(x => {
                return `${process.env.REACT_APP_BACKEND}/uploads/camps/${x}`
            })      
            const cr = result.crousal.map(x => {
                return `${process.env.REACT_APP_BACKEND}/uploads/camps/${x}`
            })
            console.log(gl,'gallery');
            console.log(cr,'crousal');
            setGalleryImage(gl)
            setCrausalImages(cr)
        }

    // const handleUpdate= async () =>{
    //     const userID = JSON.parse(localStorage.getItem('user')).result._id;
    //     const baseUrl = `${process.env.REACT_APP_BACKEND}/api/hotel/update/${params.id}`;
    //     const data = {
    //         name:name,
    //         userID:userID
    //     };

    //     const result = await axios.put(baseUrl, data);
    //     if(result.status===200){
    //         navigate('/accomodations')
    //     }
    // }

    const submitAccomodations= async (e) =>{     
        e.preventDefault();
 
        const userID = JSON.parse(localStorage.getItem('user')).result._id;
        const baseUrl = `${process.env.REACT_APP_BACKEND}/api/camp/update/${params.id}`;
        const form = document.forms.accForm

        const data = { 
            name:form.name.value,
            excerpt:form.excerpt.value,
            description:description,
            whatsincluded:whatsincluded,
            userID:userID
        };
        const formData = new FormData();
        for (let name in data) {
            formData.append(name, data[name]);
        }
        formData.append("file", file);
        crousal.map(file => {
            formData.append('crousal', file);
        })

        gallery.map(file => {
            formData.append('gallery', file);
        })

        const result = await axios({
                method: 'put',
                url:baseUrl,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' }
            });
        //const result = await axios.post(baseUrl, data);
        if(result){
            console.log(result);
            navigate('/campslist')
        }
    
    
    }

    const saveFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };

    const saveCrousal = (files) => {
        setCrousal(files);
      };

    const saveGallery = (files) => {
        setGallery(files)
      };

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Update Camp</h1>
                            </div>
                            <div className="col-sm-6">

                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                <form name='accForm' onSubmit={submitAccomodations} enctype="multipart/form-data">    
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                    </div>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Hotel Name</label>
                                                <input type="text" className="form-control" id="name" 
                                                value={name}
                                                onChange={(event) => setName(event.target.value)} placeholder="Name"/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Hotel Excerpt</label>
                                                <input type="text" className="form-control" id="excerpt" value={excerpt}
                                                onChange={(event) => setexcerpt(event.target.value)} placeholder="excerpt"/>
                                            </div>
                                            <div className="form-group col-lg-6">
                                            <label htmlFor="exampleInputEmail1">Description</label>
                                            <Editor
                                            textareaName="product Description"
                                            value={description} 
                                            //onEditorChange={(e)=>SetDescription(e.target.value)} 
                                            onEditorChange={(newValue, editor) => setdescription(newValue)}
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar: 'undo redo | formatselect | ' +
                                                'bold italic backcolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                        />
                                        </div>
                                        <div className="form-group col-lg-6">
                                        <label htmlFor="exampleInputEmail1">Whats Included</label>

                                        <Editor
                                            textareaName="product Description"
                                            value={whatsincluded} 
                                            //onEditorChange={(e)=>SetDescription(e.target.value)} 
                                            onEditorChange={(newValue, editor) => setwhatsincluded(newValue)}
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar: 'undo redo | formatselect | ' +
                                                'bold italic backcolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="exampleInputEmail1">Featured Image</label>
                                    <input
                                        accept="image/*"
                                        id="thumbnail-upload"
                                        type="file"
                                        onChange={saveFile}
                                    />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="exampleInputEmail1">Carousel images</label>
                                    <DropzoneArea
                                        acceptedFiles={['image/*']}
                                        //showPreviews={true} 
                                        //showPreviewsInDropzone={true}
                                        showFileNamesInPreview={true}
                                        initialFiles={crausalImages}
                                        type="file"
                                        onChange={saveCrousal}
                                        filesLimit={50}
                                        maxFileSize={5000000}
                                        dropzoneText="Drag and drop or click to upload up to 5 images"
                                    />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="exampleInputEmail1">Gallery</label>
                                    <DropzoneArea
                                        acceptedFiles={['image/*']}
                                        // showPreviews={true} 
                                        // showPreviewsInDropzone={true}
                                        showFileNamesInPreview={true}
                                        type="file"
                                        initialFiles={gallaryImages}
                                        onChange={saveGallery}
                                        filesLimit={500}
                                        maxFileSize={5000000}
                                        dropzoneText="Drag and drop or click to upload up to 5 images"
                                    />
                                    </div>
                                        </div>
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary">Save</button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    </form>
                </section>
            </div>
        </div>
    )
}

export default UpdateCamps;
