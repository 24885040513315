import React, { Component } from 'react';
import "../Home/Home.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import slide1 from "../../assets/slide-1.png"
import wave from "../../assets/wave.png"

const HeroSec = () => {
    const imgStyle = {
        width: '500px',
    
    }
    return (
        <>

            <div class="jumbotron jumbotron-fluid sidi-hero hero-sec-one">
                <div class="container">
                    <div className='sidi-hero-slider'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <h1 class="text-black">SURF. WINDSURF.<br />
                                    KITESURFING. SUP.</h1>
                                <img src={wave} style={imgStyle} />
                                <h4 className='text-black'>IN SIDI KAOUKI, MOROCCO</h4>
                            </div>
                            <div className='col-lg-6 '>
                                <div className='hero-second-col'>
                               <FaInstagram className="black-bg" /><span>SIDIKAOUKI.SURFSTATION</span>
                               </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeroSec;
