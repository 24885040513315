import { Button, Modal, TabContainer } from "react-bootstrap";
import axios from "axios";
import Moment from 'moment';
import { date_format, SIDI_DICTIONARY } from "../../utils/config";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { applyFormat } from "../../utils/helper";

function CalendarModel(props) {
  const base_url = process.env.REACT_APP_BACKEND
  const handleConfirm = async () =>  {
    axios.put(`${base_url}/api/confirmBooking/${props.bookingid}`).then(resp => {
      toast.success('Booking confirm.', {
        icon: '👏',
      })
    });
  }

  if(!props.data)
      return <></>
  return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Booking Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{props.bookingtitle}</h4>
          <div>
            <Table2 data={props.data}/>
          </div>
          <p>
            <Table data={props.data}/>
          </p>
        </Modal.Body>
        <Modal.Footer>
          {
            props.data.confirm !== "yes" && <Button onClick={handleConfirm}>Confirm</Button>
          }
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
}

const Table2 = (props) =>{
  const data = props.data
  if(!data)
    return <></>
  return(
    <table>
      {/* <thead>
        <tr>
          <th>Booking Detail</th>
        </tr>
      </thead> */}
      <tbody>
        {Object.keys(data).map(x => {
          if(x !== 'detail' && x !== '_id' && x !== '__v'){

            if(x === 'created_date' || x === 'updated_date' || x === 'payment_date'){
              if(data[x] != ""){
                let d = Moment(data[x]).format(date_format)
                return (<tr><td>{x} - {d}</td></tr>)
              }
            }
            return (<tr><td>{x} - {data[x]}</td></tr>)
          }
        })}
      </tbody>
    </table>
  )
}

const Table = (props) => {
    const data = props.data.detail
    const [approved,setApproved] = useState(false)
    // const [checkedState, setCheckedState] = useState(
    //   new Array(data.length).fill(false)
    // );
    const handleApproved = (e) => {
      setApproved(e.target.checked)
      if(e.target.checked){
        const id = e.target.dataset.id
        const bookingid = e.target.dataset.bookingid
        const data = {
          id:id,
          bookingid:bookingid
        };
        axios.post(`${process.env.REACT_APP_BACKEND}/api/confirmBookingItem/`,data).then(resp => {
          toast.success('Booking confirm.', {
            icon: '👏',
          })
        });
      }else{
        console.log('not checked')
      }
    }

    return(
  <>
      <div>
      {
      data.map((x,i) => <><p><hr></hr></p>
      <input type='checkbox' data-id={props.data._id} data-bookingid={x.title} onChange={handleApproved} checked={x.approveByAdmin===true}/>
      

        {
        Object.keys(x).map((a,n) => 
          
            <>
            <div className='box'>
              <div className='box-inner'>
              {a == 'approveByAdmin' || a == 'category' ? <></> : 
                <>
                  <p className='a'> {SIDI_DICTIONARY[a]}:</p>
                  <p className='b'>{applyFormat(a,x[a])}</p>
                </>
              }
            </div>
            </div>
            </>  
          
        )
        }
        <p>CheckBox For Inetrnal Management added below</p>
      <input type='checkbox' />
        </>
      )
      }
        </div>
        </>
    )
    
}
export default CalendarModel;