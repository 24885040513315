import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate,useParams } from "react-router-dom";
import { peoples } from '../../../utils/config';



const UpdatePackage = () => {
   

        const [packagename, setPName] = useState("");
        const [people, setPeople] = useState("");
        const [accomodation, setAccomodation] = useState("");
        const [price, setPrice] = useState("");
        const [name, SetAName] = useState([]);
        const params = useParams();
        const navigate = useNavigate();
        const [packages, setPackages] = useState([]);

        useEffect(()=>{
            getPackageDetails();
        },[])
    
        const getPackageDetails= async()=>{
            console.warn(params)
            let result= await fetch(`${process.env.REACT_APP_BACKEND}/api/update-packages/${params.id}`);
            result= await result.json();
            setPName(result.packagename)
            setPeople(result.people)
            setAccomodation(result.accomodation)
            setPrice(result.price)    
        }
    




        const Updatepackages= async () =>{

            const userID = JSON.parse(localStorage.getItem('user')).result._id;
           const baseUrl = `${process.env.REACT_APP_BACKEND}/api/update-packages/${params.id}`;
           const data = {
            packagename:packagename,
            people:people,
            accomodation:accomodation,
            price:price,
               userID:userID
           };

           const result = await axios.put(baseUrl, data);
           if(result.status===200){
                navigate('/packages')
        }
       }



       useEffect(() => {
        getAccomodations();
        getPackages();
      }, [])
    
      const getAccomodations = async () => {
        let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/hotel/getAll`);
        result = await result.json();
        SetAName(result);
      }

      const getPackages = async () => {
        let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/package/getAll`);
        result = await result.json();
        setPackages(result); 
      }



    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Update Package</h1>
                            </div>
                            <div className="col-sm-6">

                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                    </div>

                                    <div className="card-body">
                                        <div className='row'>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Package Name</label>
                                                    <select className="form-control" name='packageId' id="packageName">
                                                    {
                                                        packages.map((n,i) => <option key={i} value={n._id}>{n.name}</option>)
                                                    }
                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">People</label>
                                                    <select className="form-control" name='people'>
                                                        <option value={''}>Please Select Person</option>
                                                    {
                                                        peoples.map(n => <option key={n} value={n}>{n} Person</option>)
                                                    }
                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label>Accomodation</label>
                                                    <select className="form-control" name='accomodation' onChange={(e) => setAccomodation(e.target.value)}>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label>Type of Accomodation</label>
                                                    <select className="form-control" name='accomodationType'>
                                                        {name.map((accomodation) => (
                                                        <option key={accomodation._id} value={accomodation._id}>
                                                            {accomodation.name}
                                                        </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Price</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Name" value={price} onChange={e => setPrice(e.target.value)} />
                                                </div>
                                                
                                            </div>
                                        
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" onClick={Updatepackages} className="btn btn-primary">Save</button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>
            </div>
        </div>
    )
}

export default UpdatePackage;
