import React, { Component } from 'react';
import "../Home/Home.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import camel from "../../assets/camel.png"
import tree from "../../assets/tree.png"
import icon from "../../assets/icon-custom.webp"
import Wpole from "../../assets/white-pole.png"

const About = () => {
    const imgStyle = {
        height: '85px',

    }
    return (
        <>
        <section className='blue-bg-strip'></section>
            <section className='home-sec-3 py-5'>
                <div class="container">
                    <div className='row'>
                        <div className='col-lg-6'>
                            <img src={camel} />
                        </div>
                        <div className='col-lg-6'>
                            <img src={tree} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='title py-5'>
                            <img src={icon} /><h1>ABOUT SURF STATION</h1>
                        </div>
                        <p className='sec-3-subhead'>DISCOVER THE DREAM OF THE ENDLESS MOROCCAN SUMMER …</p>
                        <p className='sec-3-desc'>Lose yourself in an Arabian Nights inspired surf paradise, a corner of wild and untouched beauty. Sidi Kaouki is famous for its waves, wind and laid back surf culture, only a few km south of bustling Essaouira. Sidi Kaouki Surf Station offers all-inclusive surfing packages, special  themed surf camps and equipment rental.</p>
                    </div>
                    <div className='row'>
                        <div className='col-lg-10'>
                            <div className='row'>
                                <div className='col-lg-4 col-5'>
                                    <h1 className='timeline'>15 MINUTES</h1>
                                    <p className='timeline-text'>by car from Essaouira, Morocco</p>
                                </div>
                                <div className='col-lg-2 col-2'>
                                    <img style={imgStyle} src={Wpole} />

                                </div>
                                <div className='col-lg-4 col-5'>
                                    <h1 className='timeline'>2,5 HOURS</h1>
                                    <p className='timeline-text'>by car from Marrakesh and Agadir</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 my-5'>
                                <a href='/contact' className='see-direction'>See Direction</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;
