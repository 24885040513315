import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";import "../Contact/Contact.css"
import "../Home/Home.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import slide1 from "../../assets/slide-1.png"
import wave from "../../assets/wave.png"
import { toast } from 'react-hot-toast';

const CheckoutSuccess = () => {
    const { id } = useParams();

    useEffect(() => {
        async function updateStatus(){
            if(id !== ""){
                const data = {
                    id:id
                }
                const baseUrl = `${process.env.REACT_APP_BACKEND}/api/updatePaymentViaLink`;
                const result = await axios.post(baseUrl, data);
                if(result.status===200){
                    localStorage.setItem('cart',null)
                    toast.success('Item successfully add in cart.', {
                        icon: '👏',
                      })
                    navigate('/')
                }
            }
        }
        updateStatus();
    },[])

    const navigate = useNavigate();

    return (
        <>
            <div>Thank You{id}</div>
        </>
    );
}
export default CheckoutSuccess;
