import { Accordion, Card } from 'react-bootstrap';
import React, { Component } from 'react';
import "../Home/Home.css"
import "../Contact/Contact.css"
import icon from "../../assets/getting-there.webp"
import Bus from "../../assets/Bus.png"
import Taxi from "../../assets/taxi.png"
import Car from "../../assets/car.png"
import Camel2 from "../../assets/camel2.webp"

import sendmessage from "../../assets/send_message.webp"

import { Form, Button } from 'react-bootstrap';



const GettingThere = () => {
    const imgStyle = {
        height: '85px',

    }
    return (
        <>
            <section className='form-sec py-5'>
                <div class="container">

                    <div className='row'>
                        <div className='py-4'>
                            <img src={icon} />
                        </div>
                        <p className='sec-3-subhead'>
                            Getting to Sidi Kaouki is very easy. You can book a transfer on this site, drive yourself if you have a car, or take (local) public transport.</p>

                    </div>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>FROM ESSAOUIRA</Accordion.Header>
                            <Accordion.Body>
                                <p>There are buses and grand taxis going to Sidi Kaouki from Essaouira.</p>
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <img src={Bus} />
                                    </div>
                                    <div className='col-lg-10'>
                                        <p>
                                            The local bus number 2 leaves for Sidi Kaouki from Bab Doukkala every 2 hours and takes about 45 minutes. Tickets cost 8 dirhams and can be purchased on the bus. The ride will take approximately 1 hour, and the bus will also make a stop at the Esaouira Airport along the way. Alternatively, you can wait for one of the collective taxis that leave for Sidi Kaouki once full and are a little bit quicker taking just 25 minutes. Collectives also leave from Bab Doukkala and cost around 15 dirhams.
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <img src={Taxi} />
                                    </div>
                                    <div className='col-lg-10'>
                                        <p>
                                            Grand Taxis from Essaouira to Sidi Kaouki run during the day, and will leave when they are full. Taxis cost around 100 dirhams one-way and take about 25 minutes.</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <img src={Car} />
                                    </div>
                                    <div className='col-lg-10'>
                                        <p>
                                            Head South out of Essaouira taking the P2201 route towards Agadir. Then take the N1 south road for about 5 km before turning right onto P2201 road to Sidi Kaouki for about 8km.

                                        </p>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>FROM MARRAKESH AIRPORT</Accordion.Header>
                            <Accordion.Body>
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <img src={Bus} />
                                    </div>
                                    <div className='col-lg-10'>
                                        <p>
                                            Arrange a transfer You can request a transfer service when making your booking.<br />
                                            The best way by public transport is to take a bus to Essaouira (approximately 3 hours) with either supratours or CTM buses leaving from the main bus station and cost between 90 – 120 dirhams. Once in Essaouira there are local buses or taxis that will take you to Sidi Kaouki.
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <img src={Taxi} />
                                    </div>
                                    <div className='col-lg-10'>
                                        <p>
                                            The journey from Marrakech to Sidi Kaouki takes about 3 hours by car. Head west out of Marrakech and take the N8 towards Essaouira.
                                        </p>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>FROM AGADIR AIRPORT</Accordion.Header>
                            <Accordion.Body>
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <img src={Bus} />
                                    </div>
                                    <div className='col-lg-10'>
                                        <p>
                                            Arrange a transfer You can request a transfer service when making your booking.<br />
                                            From Agadir buses leave to Essaouira every 4 hours departing from the main bus station. Buses take around 4 hours and cost from 60-85 dirhams.
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <img src={Taxi} />
                                    </div>
                                    <div className='col-lg-10'>
                                        <p>
                                            The journey from Agadir to Sidi Kaouki takes just under 3 hours. Exit the city of Agadir north onto route N1 the coastal road towards Essaouira. Before reaching Essaouira, take a left hand turning onto P2222 route to Sidi Kaouki.
                                        </p>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>


                    <div className='row'>
                        <div className='col-lg-10'>

                        </div>
                        <div className='col-lg-2'>
                            <img src={Camel2}/>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default GettingThere;
