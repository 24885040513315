import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";


import '../index.css'
import { peoples } from '../../utils/config';

const NewPackage = () => {
        const [name, SetAName] = useState([]);
        const [packages, setPackages] = useState([]);

        const [isSuccess, setIsSuccess] = useState(false);

        const navigate = useNavigate();

        const submitpackages= async (e) =>{
            e.preventDefault();
            const userID = JSON.parse(localStorage.getItem('user')).result._id;
            const baseUrl = `${process.env.REACT_APP_BACKEND}/api/add-packages`;
            const form = document.forms.budgetForm;
            const data = {
                packageId:form.packageId.value,
                people:form.people.value,
                accomodation:form.accomodation.value,
                accomodationType:form.accomodationType.value,
                price:form.price.value,          
                userID:userID   
            };

           const result = await axios.post(baseUrl, data);
           if(result.status===200){
                navigate('/packages')
        }
       }

       useEffect(() => {
        getAccomodations();
        getPackages();
      }, [])
    
      const getAccomodations = async () => {
        let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/hotel/getAll`);
        result = await result.json();
        SetAName(result);
      }
      const getPackages = async () => {
        let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/package/getAll`);
        result = await result.json();
        setPackages(result); 
      }

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Add New Package</h1>
                            </div>
                            <div className="col-sm-6">

                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <form name='budgetForm' onSubmit={submitpackages}>
                                <div className="card card-primary">
                                    <div className="card-header">
                                    </div>

                                    <div className="card-body">
                                        <div className='row'>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Package Name</label>
                                                    
                                                    <select className="form-control" name='packageId' id="packageName">
                                                    {
                                                        packages.map((n,i) => <option key={i} value={n._id}>{n.name}</option>)
                                                    }
                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Allowed People</label>
                                                    <select className="form-control" name='people'>
                                                        <option value={''}>Please Select Person</option>
                                                    {
                                                        peoples.map(n => <option key={n} value={n}>{n} Person</option>)
                                                    }
                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label>Accomodation</label>
                                                    <select className="form-control" name='accomodation'>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </select>
                                                    
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label>Type of Accomodation</label>
                                                    <select className="form-control" name='accomodationType'>
                                                        {name.map((accomodation) => (
                                                        <option key={accomodation._id} value={accomodation._id}>
                                                            {accomodation.name}
                                                        </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Package Rate</label>
                                                    <input type="text" className="form-control"  name="price" id="price" placeholder="Package Rate" />
                                                </div>
                                                
                                            </div>
                                        
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary">Save</button>
                                    </div>
                                </div>
                                </form>

                            </div>

                        </div>

                    </div>
                </section>
            </div>
        </div>
    )
}

export default NewPackage;
