import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Form, Link, useNavigate } from "react-router-dom";


import '../index.css'
import { date_format, days, peoples } from '../../utils/config';
import { resetStorage } from '../../utils/helper';
import { FormCheck } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';

const NewCamps = () => {
        const [campsname, setPName] = useState("");
        const [people, setPeople] = useState("");
        const [accomodation, setAccomodation] = useState("");
        const [camps, setCamps] = useState([]);
        const [price, setPrice] = useState("");
        const [name, SetAName] = useState([]);
        const [isSuccess, setIsSuccess] = useState(false);
        const [isActive, setIsActive] = useState(true);
        let [campDate,setCampDate] = useState({
            startDate:new Date(),
            endDate:new Date(),
        })

        const navigate = useNavigate();

        const submitcamps= async (e) =>{
            e.preventDefault();
            const userID = JSON.parse(localStorage.getItem('user')).result._id;
            const form = document.forms.campForm
            const baseUrl = `${process.env.REACT_APP_BACKEND}/api/add-camps`;
            const data = {
                    campId:form.camp.value,
                    people:form.people.value,
                    accomodation:form.accomodation.value,
                    accomodationType:form.accomodationType.value,
                    price:form.price.value,        
                    duration:form.duration.value,        
                    userID:userID,
                    isActive:isActive,
                    campDate:{startDate : campDate.startDate,endDate : campDate.endDate}
            };
            console.log(data);
           const result = await axios.post(baseUrl, data);
           if(result.status===200){
                //resetStorage()
                navigate('/camps')
            }
       }

       useEffect(() => {
        getAccomodations();
        getCamps();
      }, [])

    const handleEvent = (event, picker) => {
        setCampDate(picker)
    }

    const handleCallback = (start, end, label) => {
        //console.log(start, end, label);
    }

    const getCamps = async () => {
        let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/camp/getAll`);
        result = await result.json();
        setCamps(result);
    }

      const getAccomodations = async () => {
        let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/hotel/getAll`);
        result = await result.json();
        SetAName(result);
      }

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Add New Camps</h1>
                            </div>
                            <div className="col-sm-6">

                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <form name="campForm" onSubmit={submitcamps}>
                                <div className="card card-primary">
                                    <div className="card-header">
                                    </div>
                                    <div className="card-body">
                                        <div className='row'>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Camps Name</label>
                                                    <select className="form-control" name='camp' id="camp" onChange={e => setPName(e.target.value)}>
                                                    {
                                                        camps.map((n,i) => <option key={i} value={n._id}>{n.name}</option>)
                                                    }
                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">People</label>
                                                    <select className="form-control" name='people'>
                                                        <option value={''}>Please Select Person</option>
                                                    {
                                                        peoples.map(n => <option key={n} value={n}>{n} Person</option>)
                                                    }
                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Duration</label>
                                                    <select className="form-control" name='duration'>
                                                    {
                                                        Object.keys(days).map((n,i) => <option key={i} value={n}>{days[n]}</option>)
                                                    }
                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label>Accomodation</label>
                                                    <select className="form-control" name='accomodation'>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </select>
                                                    
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label>Accomodation</label>
                                                    <select className="form-control" name='accomodationType'>
                                                        {name.map((accomodation) => (
                                                        <option key={accomodation._id} value={accomodation._id}>
                                                            {accomodation.name}
                                                        </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Price</label>
                                                    <input type="text" className="form-control" id="price" placeholder="Camp Rate" name='price' />
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="exampleInputEmail1">Camp Date</label>
                                                    <DateRangePicker  format={date_format} onEvent={handleEvent} onCallback={handleCallback}>
                                                        <input />
                                                    </DateRangePicker>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <FormCheck
                                                        type={'checkbox'}
                                                        id={`default-${'checkbox'}`}
                                                        label={`Active`}
                                                        name={'isActive'}
                                                        onChange={() => setIsActive(!isActive)}
                                                        checked={isActive}
                                                    />
                                                    {/* <input type="checkbox" onChange={() => setIsActive(!isActive)} className="form-control" id="isActive" name='isActive' checked={isActive} /> */}
                                                </div>
                                            </div>
                                        
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary">Save</button>
                                    </div>
                                </div>
                                </form>
                            </div>

                        </div>

                    </div>
                </section>
            </div>
        </div>
    )
}

export default NewCamps;
