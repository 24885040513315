
import React from "react"
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Intro from "./Intro";
import Desc from "./Desc";
import Gallery from "./Gallery";

const index=({handleCart, cart})=>{
    return (
        <>
        <Header handleCart={handleCart} cart={cart}/>
        <React.Fragment>
            <Intro/>
            <Desc handleCart={handleCart} cart={cart}/>
            {/* <Gallery/> */}
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index;