import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import cart from '../../assets/cart.png'
import { applyFormat, clearCart, getCartAmount } from '../../utils/helper';
import transfer from "../../assets/car.png"
import surf from "../../assets/cat-1.png"
import windsurf from "../../assets/cat-2.png"
import kitesurf from "../../assets/cat-3.png"
import sup from "../../assets/cat-4.png"
import lesson from "../../assets/icon-custom3.webp"
import './drawer.css'
import { accommodationList, camps, date_format, packages, SIDI_DICTIONARY } from '../../utils/config';
import camp from "../../assets/camps-icon2.webp"
import PackageIcon from "../../assets/package-icon.webp"
import accoIcon from "../../assets/accomodation-icon.png"
import rentalIcon from "../../assets/rentals_icon.png"
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import ClearIcon from '@mui/icons-material/Clear';

const Drawer = ({itemInCart,...props}) => {
  const [show, setShow] = useState(false);
  const [camp,setCamps] = useState([])
  const [package1,setPackage1] = useState([])
  const [accommodations,setAccommodations] = useState([])
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  useEffect(() => {
    getData();
  },[])

  const getData = async () => {
    let res = await camps()
    setCamps({ ...res })
    res = await packages()
    setPackage1({ ...res })
    res = await accommodationList()
    setAccommodations({ ...res })
  }
 
  return (
    <>
      <img onClick={handleShow} className="me-2" src={cart} />
      {itemInCart.length > 0 &&
  <>
    <p className='cart-item-length'>{itemInCart.length}</p>
  </>
}

      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> <img className="me-2" src={cart} /> Your Travel Package</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>

        {itemInCart.length === 0 && <p>Your cart is empty</p>}
{itemInCart.length > 0 &&
  <>
    <p>You have {itemInCart.length} items in Travel Package.</p>
    <Table data={itemInCart} package1={package1} camp={camp} accommodations={accommodations} />
  </>
}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

const Table = (props) => {
  const [amount,setAmount] = useState(0);
  // if(x.amount){
  //   const bill = parseFloat(amount)+parseFloat(x.amount)
  //   setAmount(bill)
  // }
  const imgStyle = {
    height: '70px',
  }
  const imgStyles = {
    height: '50px',
  }
  // function handleRemove(event) {
  //   const itemToRemove = event.target.parentNode.firstChild.textContent;
  //   cart = cart.filter(item => item !== itemToRemove);
  //   render();
  // }
  // function render() {
  //   const cartList = document.querySelector('#cart-list');
  //   cartList.innerHTML = '';
  //   cart.forEach(item => {
  //     const li = document.createElement('li');
  //     li.textContent = item;
  //     cartList.appendChild(li);
  //   });
  // }
  return(
<>
    <div>
    {
      props.data.map((x,i) => {
        
        return(
        <><p><hr></hr></p> 
        
        {
          Object.keys(x).map((a,n) => 
            
              <>
              <div className='box'>
              <div className='box-inner'>
  <table>
    <tbody>
      {a == 'category' && (
        <>
          {x[a] == 'transfer' && (
            <tr>
              <td>
                <img src={transfer} style={imgStyle} />
              </td>
            </tr>
          )}
          {x[a] == 'lesson' && (
            <tr>
              <td>
                <img src={lesson} style={imgStyle} />
              </td>
            </tr>
          )}
          {Object.keys(props.camp).find(o => o == x[a]) && (
            <tr>
              <td>
                <img src={camp} style={imgStyle} />
              </td>
            </tr>
          )}
          {Object.keys(props.package1).find(o => o == x[a]) && (
            <tr>
              <td>
                <img src={PackageIcon} style={imgStyle} />
              </td>
            </tr>
          )}
          {Object.keys(props.accommodations).find(o => o == x[a]) && (
            <tr>
              <td>
                <img src={accoIcon} style={imgStyle} />
              </td>
            </tr>
          )}
          {x[a] == 'surf' && (
            <tr>
              <td>
                <img src={surf} style={imgStyle} />
              </td>
            </tr>
          )}
          {x[a] == 'windsurf' && (
            <tr>
              <td>
                <img src={windsurf} style={imgStyle} />
              </td>
            </tr>
          )}
          {x[a] == 'kitesurf' && (
            <tr>
              <td>
                <img src={kitesurf} style={imgStyle} />
              </td>
            </tr>
          )}
          {x[a] == 'sup' && (
            <tr>
              <td>
                <img src={sup} style={imgStyle} />
              </td>
            </tr>
          )}
        </>
      )}
      {(a == 'approveByAdmin' || a == 'category') ? null : (
        <tr>
          <td>
            <p className='a'>{SIDI_DICTIONARY[a]}:</p>
          </td>
          <td>
            <p className='b'>{applyFormat(a,x[a])}</p>
          </td>
        </tr>
      )}
    </tbody>
  </table>
</div>

              </div>
              </>  
            
              
            
          )
        }
        </>
        )
      }
      )
      }
      </div>




      <div className='row'>
      <div className='text-center subt-price-class'>SUBTOTAL:€{getCartAmount()}</div>
        <Link to='/travelpackage'>
      <button type="submit" class="btn cart-btns">VIEW YOUR TRAVEL PACKAGE</button>    </Link>
      <Link to='/checkout'>
        <button type="submit" class="btn cart-btns">CHECKOUT</button></Link>   
      
        <button type="submit" class="btn cart-btns" onClick={() => clearCart()}>Clear Your Cart</button>
      </div>
      <div className='row px-2 py-3'> 
      
      
        <div className='row comp-y-pkg-draw'>
          <h4>Complete Your Pacakage</h4>
      <div className='col-md-2'>
        <a href='/prices#transfers'>
          <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Transfer
                  </Tooltip>
                }
              >
              <img src={transfer} style={imgStyles} />
              </OverlayTrigger>
        </a>
        </div>

        <div className='col-md-2'>
          <a  href='/prices#lessons'>
            <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      Lesson
                    </Tooltip>
                  }
                >  
                <img src={lesson} style={imgStyles} />
                
              </OverlayTrigger>
            </a>
        </div>
        <div className='col-md-2'>  
        <a href='/prices#camps'>
        <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  Camps
                </Tooltip>
              }
            >
            <img src={camp} style={imgStyles} />
          </OverlayTrigger>
        </a>
        </div>
        <div className='col-md-2'> 
        <a  href='/prices#packages'>
        <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  Packages
                </Tooltip>
              }
            > 
            <img src={PackageIcon} style={imgStyles} />
            </OverlayTrigger>
        </a>
        </div>
        <div className='col-md-2'>
        <a  href='/prices#accomodations'>
        <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  Accomodation
                </Tooltip>
              }
            >
            <img src={accoIcon} style={imgStyles} />
            </OverlayTrigger>
        </a>
        </div>
        <div className='col-md-2'>
          <a  href='/prices#rentals'> 
            <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      Rental
                    </Tooltip>
                  }
                >
                <img src={rentalIcon} style={imgStyles} />
            </OverlayTrigger> 
          </a>
        </div>
        </div>
      </div>
      

    {/* <table>
      <thead><tr>
        <th>Title</th>
        <th>Value</th>
        </tr></thead>
        <tbody>
      {
      props.data.map((x,i) => <><tr><hr></hr></tr> 
        {
        Object.keys(x).map((a,n) => 
          Object.keys(x[a]).map(z => 
              <>
                <tr>
                <td>{z}</td>
                <td>{x[a][z]}</td>
                </tr>
              </>  
          )
        )
        }
        </>
      )
      }
      </tbody>
      </table> */}
      </>
  )
  
}

export default Drawer;
