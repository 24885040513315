import React, { Component, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';

// const DataTable = () => {
//   const [entity, SetEntity] = useState([]);


//   useEffect(() => {
//     getEntity();
//   }, [])

//   const getEntity = async () => {
//     let result = await fetch('http://localhost:5000/api/get-entity');
//     result = await result.json();
//     SetEntity(result);
//   }
//   console.warn('entity', entity);

//   const DeleteEntity = async (id)=>{

//     // console.warn(id);
//     let result= await axios.delete(`http://localhost:5000/api/delete-entity/${id}`)
//     // result = await result.json();
//       if(result){
//         getEntity();
//       }

//   };

//   return (
//     <div>
//       <div className="content-wrapper">
//         {/* Content Header (Page header) */}
//         <section className="content-header">
//           <div className="container-fluid">
//             <div className="row mb-2">
//               <div className="col-sm-6">
//                 <h1>ENTITIES</h1>
//               </div>
//               <div className="col-sm-4 float-sm-right">
//               </div>
//               <div className="col-sm-2 float-sm-right">
//                 <Link to="/new-entity">
//                   <button type="button" class="btn btn-block bg-gradient-primary text-white">
//                     <i class="fa fa-plus px-1"></i>
//                     Add New</button>
//                 </Link>

//               </div>
//             </div>
//           </div>{/* /.container-fluid */}
//         </section>
//         {/* Main content */}
//         <section className="content">
//           <div className="container-fluid">
//             <div className="row">
//               <div className="col-12">

//                 {/* /.card */}
//                 <div className="card">
//                   <div className="card-header">
//                     {/* <h3 className="card-title">DataTable with default features</h3> */}
//                   </div>
//                   {/* /.card-header */}
//                   <div className="card-body">
//                   {entity.length ? (
//                     <table id="example1" className="table table-bordered table-striped">
//                       <thead>
//                         <tr>
//                           <th>ID</th>
//                           <th>Category</th>
//                           <th>Entity Name</th>
//                           <th>Value</th>
//                           <th>Price</th>
//                           <th>People</th>
//                           <th>Action</th>
//                         </tr>
//                       </thead>
//                       <tbody>

//                         {entity.map((item, index) => (
//                           <tr key={item._id}>
//                             <td >{index + 1}</td>
//                             <td>{item.category}</td>
//                             <td>{item.name}</td>
//                             <td>{item.value}</td>
//                             <td>{item.price}</td>
//                             <td>{item.people}</td>
//                             <td><Link to={"/update-entity/"+item._id}><i className='fas fa-pencil-alt'></i></Link> <i className='fas fa-trash-alt' onClick={() =>DeleteEntity(item._id)}></i></td>
//                           </tr>
//                         ))}


//                       </tbody>
//                       <tfoot>
//                         <tr>
//                           <th>ID</th>
//                           <th>Category</th>
//                           <th>Entity Name</th>
//                           <th>Value</th>
//                           <th>Price</th>
//                           <th>People</th>
//                           <th>Action</th>
//                         </tr>
//                       </tfoot>
//                     </table>
//                     ) : (
//                       <div>No data available</div>
//                     )}
//                   </div>
//                   {/* /.card-body */}
//                 </div>
//                 {/* /.card */}
//               </div>
//               {/* /.col */}
//             </div>
//             {/* /.row */}
//           </div>
//           {/* /.container-fluid */}
//         </section>
//         {/* /.content */}
//       </div>

//     </div>
//   )

// }

const DataTable = () => {
  const [entity, SetEntity] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    getEntity();
  }, [])

  const getEntity = async () => {
    let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-entity`);
    result = await result.json();
    SetEntity(result);
    setUniqueCategories([...new Set(result.map(item => item.category))])
  }

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  }
  const DeleteEntity = async (id) => {

    // console.warn(id);
    let result = await axios.delete(`${process.env.REACT_APP_BACKEND}/api/delete-entity/${id}`)
    // result = await result.json();
    if (result) {
      getEntity();
    }

  };
  if(entity.length === 0)
    return <></>
  // gather unique categories to render as options in the select element


  

  return (
    <div>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>ENTITIES</h1>
              </div>
              <div className="col-sm-4 float-sm-right">
              </div>
              <div className="col-sm-2 float-sm-right">
                <Link to="/new-entity">
                  <button type="button" class="btn btn-block bg-gradient-primary text-white">
                    <i class="fa fa-plus px-1"></i>
                    Add New</button>
                </Link>
              </div>
            </div>
            
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                  </div>
                  <div className="card-body">
                    <div className="row mb-2">
                      <div className="col-sm-4">
                      <div className="form-group">

                        <label>Filter by category:</label>
                        <select className="form-control" value={selectedCategory} onChange={handleCategoryChange}>
                          <option value="">All</option>
                          {uniqueCategories.map(category => (
                            <option key={category} value={category}>{category}</option>
                          ))}
                        </select>
                        </div>
                      </div>
                    </div>
                    {entity.length > 0 ? (

                      <table id="example1" className="table table-bordered table-striped">

                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Category</th>
                            <th>Entity Name</th>
                            <th>Value</th>
                            <th>Price</th>
                            <th>People</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {entity
                            .filter(item => !selectedCategory || item.category === selectedCategory)
                            .map((item, index) => (
                              <tr key={item._id}>
                                <td>{index + 1}</td>
                                <td>{item.category}</td>
                                <td>{item.name}</td>
                                <td>{item.value}</td>
                                <td>{item.price}</td>
                                <td>{item.people}</td>
                                <td>
                                  <Link to={"/update-entity/" + item._id}>
                                    <i className='fas fa-pencil-alt'></i>
                                  </Link>
                                  <i className='fas fa-trash-alt px-2' onClick={() => DeleteEntity(item._id)}></i>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>ID</th>
                            <th>Category</th>
                            <th>Entity Name</th>
                            <th>Value</th>
                            <th>Price</th>
                            <th>People</th>
                            <th>Action</th>
                          </tr>
                        </tfoot>
                      </table>
                    ) : (
                      <div>No data available</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default DataTable