import React from 'react';
import { Tab } from 'react-bootstrap';

const CustomTabs = ({ title, imgsrc, ...props }) => {
    return (
        <Tab {...props}>
            <img src={imgsrc} style={{width:'350px'}} alt="tab-img" />
            <span className="ml-2">{title}</span>
        </Tab>
    );
};

export default CustomTabs;