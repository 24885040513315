import React, { Component, useEffect, useRef, useState } from 'react';
import "../Home/Home.css"
import "./Prices.css"
import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import rental1 from "../../assets/rental1.png"
import rental2 from "../../assets/rental2.png"
import icon from "../../assets/rentals_icon.png"
import rental3 from "../../assets/rental3.png"

import Surf1 from "../../assets/surf1.png"
import Surf2 from "../../assets/surf2.png"
import Surf3 from "../../assets/surf3.png"
import Surf4 from "../../assets/surf4.png"
import Surf5 from "../../assets/surf5.png"
import Surf6 from "../../assets/surf6.png"
import Windsurf1 from "../../assets/windsurf1.png"
import Windsurf2 from "../../assets/windsurf2.png"
import Windsurf3 from "../../assets/windsurf3.png"
import Windsurf4 from "../../assets/windsurf4.png"
import Windsurf5 from "../../assets/windsurf5.png"
import Windsurf6 from "../../assets/windsurf6.png"
import Windsurf7 from "../../assets/windsurf7.png"
import Windsurf8 from "../../assets/windsurf8.png"
import Windsurf9 from "../../assets/windsurf9.png"
import Windsurf10 from "../../assets/windsurf10.png"
import Kite1 from "../../assets/kite1.png"
import Kite2 from "../../assets/kite2.png"
import Kite3 from "../../assets/kite3.png"
import Kite4 from "../../assets/kite4.png"
import Kite5 from "../../assets/kite5.png"
import Kite6 from "../../assets/kite6.png"
import Sup1 from "../../assets/sup1.png"
import Sup2 from "../../assets/sup2.png"
import Sup3 from "../../assets/sup3.png"
import Sup4 from "../../assets/sup4.png"
import Sup5 from "../../assets/sup5.png"



import windsurfImg from "../../assets/windsurf.png"
import kitesurfImg from "../../assets/kitesurf.png"
import supImg from "../../assets/sup.png"
import CustomTab from './CustomTabs';
import axios from 'axios';
import Windsurf from '../../Components/Rentals/Windsurf';
import Kitesurf from '../../Components/Rentals/Kitesurf';
import Sup from '../../Components/Rentals/Sup';
import Carousel from 'react-multi-carousel';
import { date_format, days, peoples, supDays, surfDays } from '../../utils/config';
import { addInCart, setDate, triggerNativeEventFor } from '../../utils/helper';
import { DatePicker } from 'antd';
import { toast } from 'react-hot-toast';


const Rentals = ({handleCart, cart}) => {
    const imgStyle = {
        height: '85px',
    }
    const rentalfeatured = {
        width: '100%',
    }
    const tabimg = {
        width: '200px',
    }

    const base_wetsuit = 4;

    const [result,setResult] = useState([]);
    const [surf,setSurf] = useState([]);
    const [windsurfResult,setWindsurfResult] = useState([]);
    const [kitesurfResult,setKiteSurfResult] = useState([]);
    const [supResult,setSupResult] = useState([]);
    const [rateList,setRateList] = useState([]);
    const [people,setPeople] = useState(1);
    const [duration,setDuration] = useState(1);
    const durationRef = useRef()
    const [amount,setAmount] = useState(0);
    const [type,setType] = useState("soft")
    const [addWetsuit,setAddWetsuit] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/api/getRentals`).then(resp => {
            setResult(resp.data.slice());
        });
    },[])

    useEffect(() => {
        setSurf(result.filter(x => x.name.toLowerCase() == "surf"))
        setWindsurfResult(result.filter(x => x.name.toLowerCase() == "windsurf"))
        setKiteSurfResult(result.filter(x => x.name.toLowerCase() == "kitesurf"))
        setSupResult(result.filter(x => x.name.toLowerCase() == "sup"))
    },[result])

    const calculateSurf = () => {
        const data = surf.filter(x => x.type.toLowerCase() === type);
        if(data.length > 0){
            const durationList = data.map(a => {
                var index = surfDays[a.duration]
                const wetsuitPrice = (addWetsuit ? ( index != undefined ? base_wetsuit*index.day*people : 0) : 0);
                return {
                    price:(a.price*people)+wetsuitPrice,
                    name:`${a.duration} (+€${(a.price*people)+wetsuitPrice})`,
                    value:a.duration,
                }
            })
            setRateList(durationList);
        }
    }

    useEffect(() => {
        calculateSurf();
    },[people,type,addWetsuit])

    useEffect(() => {
        if(rateList.length > 0 && duration){
            triggerNativeEventFor(durationRef.current,{ event: 'change', value: duration})
        }
    },[duration,rateList])

    const handlePeople = (e) => {
        setPeople(e.target.value)
    }

    const handleBoardType = changeEvent => {
        setType(changeEvent.target.value);
    };

    const handleRate = (e) => {
        if(e.target.selectedIndex > -1){
            const price = e.target[e.target.selectedIndex].dataset.price
            setDuration(e.target.value)
            setAmount(price);
        }
    }
    
    const bookNow = async (e) => {
        e.preventDefault();
        const form = document.forms.surf
        if(amount <= 0){
            toast.error('please select Surf')
            return;
        }else if(form.surfDate.value === undefined || form.surfDate.value === ""){
            toast.error('please select Surf date!.')
            return;
        }
        
        const details = 
            {
                category:'surf',
                title:'Surf',
                typeOfBoard:type,
                people:form.people.value,
                duration:form.duration.value,
                reservationDate:setDate(form.surfDate.value),
                wetsuit:addWetsuit ? 'yes' : 'no',
                amount:amount
            }
        const itemInCart = await addInCart(details,'surf');
        if(itemInCart.status){
            handleCart([...cart,details])
            toast.success('Item successfully add in cart.', {
                icon: '👏',
              })
        }else{
            toast.error(itemInCart.message)
        }
    }
    const rentalsRef = useRef();
    useEffect(() => {
        const timer = setTimeout(() => {
            if (window.location.hash === "#rentals") {
                if(rentalsRef.current){
                    rentalsRef.current.scrollIntoView();
                }
            }
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
    const caroselStyle = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
          },
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
          },
        };

        const cStyle = {
            height: '250px',
        }
    return (
        <>
        <section className='blue-bg-stripss'></section>
            <section className='prices-sec-3 py-5' id='rentals' ref={rentalsRef}>
                <div class="container">

                    <div className='row'>
                        <div className='title'>
                            <img src={icon} style={imgStyle} /><h1>RENTALS</h1>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <img src={rental1} style={rentalfeatured} />
                        </div>
                        <div className='col-lg-4'>
                            <img src={rental2} style={rentalfeatured} />
                        </div>
                        <div className='col-lg-4'>
                            <img src={rental3} style={rentalfeatured} />
                        </div>
                    </div>
                    <div className='row rentals-tab'>
                        <h3 className='py-4'>PLEASE CHOOSE YOUR SPORT:</h3>
                        <Tabs defaultActiveKey="profile" id="fill-tab-example" className="mb-3" fill>
                            <CustomTab eventKey="home" title="SURF" imgsrc={rental1}>
                           <Carousel
                           responsive={caroselStyle}
                           autoPlay
                           autoPlaySpeed={3000}
                           infinite
                           >
                                <img src={Surf1} style={cStyle}/>
                                <img src={Surf2} style={cStyle}/>
                                <img src={Surf4} style={cStyle}/>
                                <img src={Surf6} style={cStyle}/>
                                <img src={Surf5} style={cStyle}/>
                                <img src={Surf3} style={cStyle}/>


                           </Carousel>
                            <h5 className='py-4'>
                            SIDI KAOUKI IS AL LONG OPEN BEACH BREAK WITH LOADS OF PUNCHY
PEAKS AND A BIT OF REEF AT THE RIVER MOUTH AND SOUTH
END. GOOD SHAPE ON THE RIGHT SWELLS AS LONG AS WIND IS E
QUADRANT.                            </h5>

                                <form name='surf' onSubmit={bookNow}>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Type of Board</label>

                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" value="soft" 
                                                                checked={type === 'soft'} 
                                                                onChange={handleBoardType} />
                                                    <label className="form-check-label">Soft</label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" value="advanced" 
                                                                checked={type === 'advanced'} 
                                                                onChange={handleBoardType} />
                                                    <label className="form-check-label">Advanced</label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" value="pro" 
                                                                checked={type === 'pro'} 
                                                                onChange={handleBoardType} />
                                                    <label className="form-check-label">Pro</label>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-sm-3">

                                            <div className="form-group">
                                                <label>No of people</label>
                                                <select className="form-control" name='people' onChange={handlePeople}>
                                                    <option key={'11'} value={''}>Please Select Person</option>
                                                {
                                                    peoples.map(n => <option key={n} value={n}>{n} Person</option>)
                                                }
                                                </select>
                                            </div>


                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Duration</label>
                                                <select className="form-control" name='duration' ref={durationRef} onChange={handleRate}>
                                                <option key={'11'} value={''}> Select Option </option>

                                                    {rateList.length > 0 && 
                                                        rateList.map(a => <option value={a.value} data-price={a.price} data-duraion={a.duration}>{a.name}</option>)
                                                    }
                                                </select>
                                            </div>
                                            {/* text input */}

                                        </div>
                                        <div className="col-sm-3">
                                            <div class="form-group">
                                                <label>Date:</label><br/>
                                                <DatePicker
                                                    name="surfDate"
                                                    format={date_format}
                                                />
                                              
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div class="form-group">
                                                <div class="form-check">
                                                <input class="form-check-input" name='wetsuit' type="checkbox" checked={addWetsuit} onChange={(e) => setAddWetsuit(e.target.checked)}/>
                                                    <label class="form-check-label">Need Wetsuit</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-check-label">Total Surf Amount : €{amount}</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <button type="submit" className="btn book-now-btn"> <i className='fas fa-suitcase px-2'></i>ADD TO YOUR TRAVEL</button>
                                        </div>
                                    </div>
                                </form>
                            </CustomTab>
                            <CustomTab eventKey="profile" title="WINDSURF" imgsrc={windsurfImg}>
                            <Carousel
                           responsive={caroselStyle}
                           autoPlay
                           autoPlaySpeed={3000}
                           infinite
                           >
                                <img src={Windsurf1} style={cStyle}/>
                                <img src={Windsurf2} style={cStyle}/>
                                <img src={Windsurf3} style={cStyle}/>
                                <img src={Windsurf4} style={cStyle}/>
                                <img src={Windsurf5} style={cStyle}/>
                                <img src={Windsurf6} style={cStyle}/>
                                <img src={Windsurf7} style={cStyle}/>
                                <img src={Windsurf8} style={cStyle}/>
                                <img src={Windsurf9} style={cStyle}/>
                                <img src={Windsurf10} style={cStyle}/>

                           </Carousel>
                            <h5 className='py-4'>
                            Sidi Kaouki belongs to one of the world’s top windsurfing spots, in line with Hookipa and Sunset Beach. Here
you’ll have plenty more space, and nice waves waiting just for you. The strong wind and clean shore broken
waves of 1-3 meters, provide year-round the best conditions for windsurfing.
                            </h5>
                                <Windsurf data={windsurfResult} days={days} wetsuit={base_wetsuit} noOfPeople={peoples} handleCart={handleCart} cart={cart}/>
                            </CustomTab>
                            <CustomTab eventKey="longer-tab" title="KITESURF" imgsrc={kitesurfImg}>
                            <Carousel
                           responsive={caroselStyle}
                           autoPlay
                           autoPlaySpeed={3000}
                           infinite
                           >
                                <img src={Kite1} style={cStyle}/>
                                <img src={Kite2} style={cStyle}/>
                                <img src={Kite3} style={cStyle}/>
                                <img src={Kite4} style={cStyle}/>
                                <img src={Kite5} style={cStyle}/>
                                <img src={Kite6} style={cStyle}/>

                           </Carousel>
                            <h5 className='py-4'>
                            In Sidi Kaouki all level kite surfers can have lots of fun. From the beginner to crack freestyle and wave riding.
<br/>
<br/>
The conditions in Sidi Kaouki are perfect for your first dry training. You will have endless wide lonely sandy
beaches without any risk of getting in trouble with anyone or anything even during beach training or with
bodydragging. Not even one stone or rock in the water will handicap you. Constant blowing wind helps you to
get a good feeling of the kite very fast.
<br/>
<br/>
Cracks will love the clean beachbreack with sideshore conditions to use the Atlantic as their playground. Nearly
everyday you will have the chance to go into water, since there’s always more wind than in the surrounding of
Essaouira. The 5km long bay of find sand makes it easy to self launch and land your kite without any struggles. 
                            </h5>
                                <Kitesurf data={kitesurfResult} days={days} wetsuit={base_wetsuit} noOfPeople={peoples} handleCart={handleCart} cart={cart}/>
                            </CustomTab>
                            <CustomTab eventKey="contact" title="SUP" imgsrc={supImg} >
                            <Carousel
                           responsive={caroselStyle}
                           autoPlay
                           autoPlaySpeed={3000}
                           infinite
                           >
                                <img src={Sup1} style={cStyle}/>
                                <img src={Sup2} style={cStyle}/>
                                <img src={Sup3} style={cStyle}/>
                                <img src={Sup4} style={cStyle}/>
                                <img src={Sup5} style={cStyle}/>

                           </Carousel>
                            <h5 className='py-4'>SUP on a lake it is nice, but the real fun comes by getting pushed by the ocean waves. Sidi Kaouki offers great conditions for all levels to enjoy SUPing along the lovely coast. 
                            </h5>
                                <Sup data={supResult} days={supDays} wetsuit={base_wetsuit} noOfPeople={peoples} handleCart={handleCart} cart={cart}/>
                            </CustomTab>
                        </Tabs>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Rentals;
